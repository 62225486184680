import axios from 'axios';

const service = axios.create({
    // 环境变量，需要在.env文件中配置
    baseURL: '/api/',
    // 超时时间暂定5s
    timeout: 5000,
});

service.interceptors.request.use(
    config => {
        // 此处添加Loading
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const Request = (url, options = {}) => {
    let method = options.method || 'get';
    let params = options.params || {};

    if (method === 'get' || method === 'GET') {
        return new Promise((resolve, reject) => {
            service
                .get(url, {
                    params: params,
                })
                .then(res => {
                    if (res && res.data) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            service
                .post(url, params)
                .then(res => {
                    if (res && res.data) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

export default Request;