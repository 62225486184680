<template>
    <div class="product-wrap wlcx-wrap">
        <div class="banner">
            <img class="banner"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/ddlj/banner-1.png"
                alt="">
            <div class="content">
                <div class="title">订单拦截</div>
                <div class="desc">解决商家或商家客户在发件后需要退回、修改地址的问题，为商家或商家客户提供的退件或修改地址的服务</div>
                <div class="btn-list">
                    <span @click="handleRegisterJump" class="btn-zixun">免费体验</span>
                    <span @click="handlePriceJump" class="btn-tiyan">查看价格</span>
                </div>
            </div>
        </div>

        <div class="nav-wrap">
            <div class="item nav-item">订单拦截 <img class="icon"
                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/arrow-down.png"
                    alt="">
            </div>
            <router-link to="#cpgn-wrap" class="item item-active">拦截服务</router-link>
            <router-link to="#cpys-wrap" class="item">应用场景</router-link>
            <router-link to="#jrlc-wrap" class="item">接入流程</router-link>
        </div>

        <div class="cpgn-wrap" id="cpgn-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    拦截服务
                </div>
                <div class="title-shadow">
                    Intercept Service
                </div>
            </div>

            <div class="btn-group">
                <div class="func-btn-1 item item-active">快递信息推送服务</div>
                <div class="func-btn-1 item item-active">拦截退改服务</div>
                <div class="func-btn-1 item item-active">拦截退改状态</div>
            </div>

            <img  class="fangshi-img"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/ddlj/banner-2.png" />
        </div>

        <div class="yycj-wrap" id="cpys-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    应用场景
                </div>
                <div class="title-shadow">
                    scenario
                </div>
            </div>

            <div class="changjing-list">
                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/ddlj/changjing-1.png"
                            alt="">
                        <div class="title">商家发起拦截退改服务</div>
                        <div class="sub-title">Merchants initiate refund/change interception service</div>
                    </div>
                    <div class="bottom">
                        商家从电商平台等各种渠道接受到消费者改地址、退件的诉求，向物流云发起拦截退改服务工单，要求物流云按工单要求执行派送任务。
                    </div>
                </div>

                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/ddlj/changjing-2.png"
                            alt="">
                        <div class="title">商家撤销拦截退改服务</div>
                        <div class="sub-title">Merchants cancel refund/change interception</div>
                    </div>
                    <div class="bottom">
                        当收件人原本拒收的订单愿意签收或者原本停发的地址恢复正常派送等场景，要求物流云按订单原来的运输要求执行派送任务。
                    </div>
                </div>

                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/ddlj/changjing-3.png"
                            alt="">
                        <div class="title">商家查看拦截退改处理进展</div>
                        <div class="sub-title">Merchants view refund/change interception progress</div>
                    </div>
                    <div class="bottom">
                        商家及时通过自研软件跟进工单处理结果，必要时将最新的进展同步给消费者，形成完整的业务闭环，提升商家及消费者物流服务体验。
                    </div>
                </div>
            </div>
        </div>

        <div class="column-wrap jrlc-wrap" id="jrlc-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    接入流程
                </div>
                <div class="title-shadow">
                    pick-up
                </div>
            </div>

            <div class="liucheng-list">
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-1.png"
                        alt="">
                    <div class="title">账号注册</div>
                    <div class="desc">点击「免费注册」输入手机号和验证码完成注册</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-2.png"
                        alt="">
                    <div class="title">开通服务</div>
                    <div class="desc">开通相关的功能服务</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-3.png"
                        alt="">
                    <div class="title">开发和联调</div>
                    <div class="desc">获取AppKey/AppSecret进行API调用</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-4.png"
                        alt="">
                    <div class="title">开始使用</div>
                    <div class="desc">开始使用浩鲸物流的功能</div>
                </div>

                <div class="arrow-list">
                    <div class="link-arrow link-arrow-1"></div>
                    <div class="link-arrow link-arrow-2"></div>
                    <div class="link-arrow link-arrow-3"></div>
                </div>

            </div>
            <a href="javascript:void(0)" class="contact-btn track-lxwm-el">联系我们</a>
        </div>

        <MoreItem />

        <!-- 联系我们 -->
        <div class="column-wrap contact-wrap">
            <div class="contact-content">
                <div class="left">
                    <div class="label">告诉我们您的需求</div>
                    <div class="desc">我们很乐意听取您的需求，并讨论我们如何帮助。请填写右侧表格，安排电话或讨论，并在您方便的时间会面。</div>
                    <div class="dashborder"></div>
                    <div class="label-2">
                        <p>关于您的预订有任何问题吗？</p>
                        <p>您可以通过以下方式与我们联系：</p>
                    </div>
                    <div class="contact-list">
                        <p>咨询电话：15645112352</p>
                        <p>合作邮箱：1721003310@qq.com</p>
                        <p>办公地址：北京市朝阳区望京绿地中心B座1903</p>
                    </div>
                </div>

                <CommonForm />
            </div>
        </div>
    </div>
</template>

<script>
import CommonForm from '../../components/CommonForm.vue'
import MoreItem from '../../components/MoreItem.vue'
export default {
    data() {
        return {
            fangshiIndex: 0,
            hexinIndex: 0,
            hexinList: [
                '订阅',
                '揽收后监控',
                '运输节点监控',
                '派件中监控',
                '签收监控',
                '退回件监控',
            ],
            descList: [
                ['揽收后监控，可以针对揽收后长时间无物流信息的单号发出预警', '订阅单号时声明监控，系统会自动计算，无需额外对接', '支持自行配置揽收预警的时间阈值']
            ]
        }
    },
    components: {
        CommonForm,
        MoreItem
    },
    mounted() {
        // document.querySelector('.func-btn-1').onmouseenter = function () {
        //     btnTab1Func()
        // }

        // document.querySelector('.func-btn-2').onmouseenter = function () {
        //     btnTab2Func()
        // }


        function btnTab1Func() {
            document.querySelector('.func-btn-1').classList.add('item-active')
            document.querySelector('.func-btn-2').classList.remove('item-active')
            document.querySelector('.cpgn-wrap .right .desc').textContent = '输入完整的寄件人、收件人地址信息以及快递单号，可以更准确地查询到包裹的预计到达时间。此时系统会综合考虑各种因素来预估包裹的运输时间。'
            document.querySelector('.cpgn-wrap .phone').src = 'https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/sxyc/fangshi-1.png'
        }

        function btnTab2Func() {
            document.querySelector('.func-btn-1').classList.remove('item-active')
            document.querySelector('.func-btn-2').classList.add('item-active')
            document.querySelector('.cpgn-wrap .right .desc').textContent = '输入快递单号，即可查询快递的大概到达时间。需要注意的是，基础查询只能提供包裹的大概到达时间，实际情况可能会因天气、道路等因素而有所偏差。'
            document.querySelector('.cpgn-wrap .phone').src = 'https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/sxyc/fangshi-2.png'
        }

        function getQueryString(name) {
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
            var r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            }
            return null;
        }

        if (getQueryString('tab')) {
            if (getQueryString('tab') == 1) {
                btnTab1Func()
            } else {
                btnTab2Func()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../static/style/product.common.scss';

.cpgn-wrap {
    padding: 80px 159px 59px 210px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;



    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .fangshi-img {
        width: 100%;
        height: auto;
    }

    .fangshi-banner {
        width: 100%;
        height: auto;
        margin-top: 30px;
    }

    .btn-group {
        margin: 48px auto;
        display: flex;
        align-items: center;

        .item {
            min-width: 110px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 18px;
            color: #131424;
            margin-right: 20px;
            padding: 0 20px;
            cursor: pointer;
        }

        .item-active {
            color: #fff;
            background-color: #003CE2;
            border-radius: 22px;
        }
    }

    .desc {
        margin-top: 33px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 16px;
        color: #02112E;
        letter-spacing: 0;
        text-align: justify;
    }

    .process {
        width: 493px;
        height: 163px;
        margin-top: 77px;
    }

}

.yycj-wrap {
    padding: 49px 149px 53px;

    .changjing-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .changjing-item {
            width: 368px;
            border-radius: 12px;
            overflow: hidden;

            .top {
                height: 222px;
                position: relative;

                >img {
                    width: 100%;
                    height: 100%;
                }

                .title {
                    height: 28px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 42px;
                    left: 20px;
                }

                .sub-title {
                    height: 17px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 12px;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 26px;
                    left: 20px;
                }
            }

            .bottom {
                height: 117px;
                background: #F7F8FB;
                padding: 20px;
                box-sizing: border-box;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 14px;
                color: #828282;
            }
        }
    }
}

.cpys-wrap {
    padding: 49px 149px 53px;

    display: flex;
    align-items: stretch;

    .left {
        width: 375px;

        .item {
            margin-bottom: 18px;
            width: 375px;
            height: 64px;
            line-height: 64px;
            cursor: pointer;
            padding-left: 26px;
            padding-right: 26px;
            box-sizing: border-box;
            background: #FFFFFF;
            box-shadow: 0 0 12px 0 rgba($color: #000000, $alpha: 0.1);
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon {
                width: 13px;
                height: 22px;
            }
        }

        .item-active {
            background-color: #0555FF;
            color: #fff;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-left: 60px;

        .step-list {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            position: relative;
            margin-top: 37px;

            &::after {
                content: "";
                width: 90%;
                height: 2px;
                background-color: #E3ECFF;
                position: absolute;
                top: 30px;
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
            }

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .logo {
                    width: 50px;
                    height: 50px;
                }

                >p {
                    color: #000;
                    font-size: 14px;
                    font-weight: bold;
                    margin-top: 5px;
                }
            }

            .item-active {
                .logo {
                    transform: scale(1.5);
                    transition: all .2s;
                }

                >p {
                    font-size: 16px;
                    margin-top: 10px;
                    color: #0555FF;
                }
            }
        }

        .desc-list {
            margin-top: 48px;

            >p {
                font-size: 16px;
                line-height: 18px;
                margin-top: 20px;
                color: #000;

            }
        }

        .link-btn {
            font-size: 15px;
            margin-top: 25px;
            color: #003CE2;
            display: flex;
            align-items: center;

            .link-icon {
                width: 15px;
                height: 15px;
                margin-left: 3px;

            }
        }
    }

    .youshi-list {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item {
            box-sizing: border-box;
            width: 368px;
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            box-shadow: 0 0 12px 0 #0000000d;
            border-radius: 12px;
            display: flex;
            justify-content: space-between;
            padding: 30px 20px;


            .left {
                .title {
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #02112E;
                }

                .desc {
                    margin-top: 10px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 12px;
                    color: #828282;
                    letter-spacing: 0;
                    line-height: 20px;
                }
            }

            .icon {
                width: 64px;
                height: 64px;
                margin-left: 11px;
            }
        }
    }
}

.yhjs-wrap {
    background: #F7F9FC;

    .juese-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .item {
            width: 368px;
            background: #FFFFFF;
            box-shadow: 0 0 8px 0 #00000005;
            border-radius: 12px;
            padding: 38px 29px;
            box-sizing: border-box;
            transition: all .3s;

            &:hover {

                .title,
                .desc,
                .link {
                    color: #ffffff !important;
                }

                background-color: #003CE2 !important;
            }

            .icon {
                width: 63px;
                height: 62px;
            }

            .icon-w {
                width: 63px;
                height: 62px;
                display: none;
            }

            &:hover {
                .icon {
                    display: none;
                }

                .icon-w {
                    display: block;
                }
            }

            .title {
                margin-top: 34px;
                line-height: 42px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 30px;
                color: #02112E;
                letter-spacing: 0;
            }

            .desc {
                margin-top: 17px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 16px;
                color: #131424;
                letter-spacing: 0;
                line-height: 24px;
            }

            .link {
                margin-top: 20px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 15px;
                color: #003CE2;
                letter-spacing: 0;
                display: flex;
                align-items: center;

                .link-icon {
                    margin-left: 3px;
                    width: 15px;
                    height: 15px;
                }

                .link-icon-w {
                    display: none;
                    margin-left: 3px;
                    width: 15px;
                    height: 15px;
                }
            }

            &:hover {
                .link-icon {
                    display: none;
                }

                .link-icon-w {
                    display: block;
                }
            }
        }
    }
}

.jrlc-wrap {

    .liucheng-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        position: relative;

        .arrow-list {
            position: absolute;
            top: 53px;
            left: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 265px;
            box-sizing: border-box;
            width: 100%;

            .link-arrow {
                display: flex;
                width: 48px;
                height: 48px;
                background: #FFFFFF;
                border-radius: 50%;
                // background-image: url('../../images/product/wlcx/link-right.png');
                background-position: center;
                background-size: 26px 26px;
                background-repeat: no-repeat;
                box-shadow: 0 0 12px 0 #0000000d;
            }
        }

        .item {
            width: 277px;
            background-image: linear-gradient(180deg, #FBFCFD 0%, #F6F7FA 100%);
            border-radius: 12px;

            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 49px 0 38px;

            .icon {
                width: 80px;
                height: 78px;
            }

            .title {
                margin-top: 33px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 20px;
                color: #02112E;
                letter-spacing: 0;
                text-align: center;
            }

            .desc {
                margin-top: 22px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #131424;
                letter-spacing: 0;
                text-align: center;
                line-height: 20px;
                width: 227px;
                height: 40px;
            }
        }
    }

    .contact-btn {
        margin: 50px auto 0;
        width: 201px;
        height: 50px;
        background: #003CE2;
        font-family: PingFangSC-SNaNpxibold;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        border-radius: 4px;
        display: block;
        color: #fff;
        box-shadow: 0 0 12px 0 #003CE20d;
    }
}
</style>