<template>
    <div :class="[activeIndex == 0 ? 'home-nav-container-wrap' : '', 'nav-container-wrap']">

        <div
            :class="[activeIndex == 0 ? 'home-nav-container' : '', 'nav-container', navDark ? 'dark-theme' : '', logoColor == 'blue' ? 'blue-text' : '']">
            <router-link class="logo-icon-wrap" to="/home">
                <img class="logo-icon"
                    :src="logoColor == 'white' ? 'https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/logo-white.png' : 'https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/logo-blue.png'"
                    alt="">
            </router-link>

            <router-link :class="['nav-container-item', activeIndex == 'yingxiao' ? 'nav-container-item-active' : '']"
                to="/yingxiao">
                <div class="nav-item">最新活动 <div class="yingxiao-badge">HOT</div>
                </div>
            </router-link>

            <router-link :class="['nav-container-item', activeIndex == 'home' ? 'nav-container-item-active' : '']"
                to="/home">首页</router-link>
            <div id="serve-item"
                :class="['nav-container-item serve-item', activeIndex == 'product' ? 'nav-container-item-active' : '']"
                href="/kdcx" target="_blank">产品服务
                <div class="nav-expand-wrap">
                    <!-- <a class="nav-expand-wrap-item" href="/kdcx" target="_blank">物流轨迹查询</a>
            <a class="nav-expand-wrap-item" href="/kdcx-copy" target="_blank">物流地图轨迹查询</a> -->

                    <div class="nav-column-list">
                        <div class="nav-column">
                            <div class="nav-column-title">物流查询类</div>

                            <router-link class="nav-column-link" to="/kdcx?tab=2">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-wlcx.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">物流信息查询</div>
                                    <div class="desc">根据订单后实时获取物流状态信息</div>
                                </div>
                            </router-link>

                            <router-link class="nav-column-link" to="/kdcx?tab=1">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-wlcx-1.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">物流信息推送</div>
                                    <div class="desc">订阅订单后，实时推送物流状态信息</div>
                                </div>
                            </router-link>

                            <router-link class="nav-column-link" to="/dtgj">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-dtgj.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">地图轨迹与订阅</div>
                                    <div class="desc">在地图上直观展示快递位置轨迹</div>
                                </div>
                            </router-link>

                            <router-link class="nav-column-link" to="/gjwldy">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-gjwldy.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">国际物流订阅</div>
                                    <div class="desc">一站式国际物流订阅</div>
                                </div>
                            </router-link>
                        </div>

                        <div class="nav-column">
                            <div class="nav-column-title">时效预测类</div>

                            <router-link class="nav-column-link" to="/sxyc?tab=1">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-sxyc.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">时效预测(发货前)</div>
                                    <div class="desc">线路时效预估选择更好的快递公司</div>
                                </div>
                            </router-link>

                            <router-link class="nav-column-link" to="/sxyc?tab=2">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-sxyc-1.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">时效预测(发货后)</div>
                                    <div class="desc">快速解决用户关于时效的售后服务</div>
                                </div>
                            </router-link>

                            <router-link class="nav-column-link" to="/wlycyj">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-wlycyj.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">物流异常预警</div>
                                    <div class="desc">异常订单实时监控和消息推送</div>
                                </div>
                            </router-link>
                        </div>

                        <div class="nav-column">
                            <div class="nav-column-title">订单类</div>

                            <router-link class="nav-column-link" to="/dzmd">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-dzmd.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">电子面单</div>
                                    <div class="desc">批量打印面单，支持多家快递模板</div>
                                </div>
                            </router-link>


                            <router-link class="nav-column-link" to="/ddlj">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-ddlj.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">订单拦截</div>
                                    <div class="desc">为用户提供物流地址修改服务</div>
                                </div>
                            </router-link>
                        </div>

                        <div class="nav-column">
                            <div class="nav-column-title">增值服务</div>

                            <router-link class="nav-column-link" to="/grjj">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-grjj.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">商家寄件</div>
                                    <div class="desc">在线通知快递员上门取件</div>
                                </div>
                            </router-link>

                            <router-link class="nav-column-link" to="/gjdzk">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-gjdzk.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">国际地址库</div>
                                    <div class="desc">对海外地址数据的识别解析补全</div>
                                </div>
                            </router-link>

                            <router-link class="nav-column-link" to="/dhsb">
                                <img class="icon"
                                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/nav/nav-ddsb.png"
                                    alt="">
                                <div class="context">
                                    <div class="label">单号识别</div>
                                    <div class="desc">自动识别物流公司，返回物流编码</div>
                                </div>
                            </router-link>

                        </div>
                    </div>

                    <!-- <a target="_blank" href="/kdcx?tab=1">物流信息推送</a>
            <a target="_blank" href="/kdcx?tab=2">物流信息查询</a>
            <a target="_blank" href="/dtgj">地图轨迹与订阅</a>
            <a target="_blank" href="/sxyc">时效预测</a>
            <a target="_blank" href="/dzmd">电子面单</a>
            <a target="_blank" href="/gjwlgj">国际物流轨迹</a>
            <div style="width: 250px;"></div> -->
                </div>
            </div>
            <router-link :class="['nav-container-item', activeIndex == 'cpjg' ? 'nav-container-item-active' : '']"
                to="/cpjg">产品定价</router-link>
            <a :class="['nav-container-item', activeIndex == 3 ? 'nav-container-item-active' : '']"
                href="https://www.yuque.com/u34567120/gk2t1c" target="_blank">接口API文档</a>
            <router-link :class="['nav-container-item', activeIndex == 'jjfa' ? 'nav-container-item-active' : '']"
                to="/jjfa">解决方案
            </router-link>
            <router-link :class="['nav-container-item', activeIndex == 'xzwm' ? 'nav-container-item-active' : '']"
                to="/xzwm">关于我们</router-link>

            <div class="login-wrap">
                <span @click="handleLoginJump"
                    :class="['login-btn track-login-el', activeIndex == 0 ? 'home-login-btn' : '', logoColor == 'blue' ? 'login-blue' : '']">登录</span>
                <span @click="handleRegisterJump"
                    :class="['register-btn track-register-el', activeIndex == 0 ? 'home-register-btn' : '']">
                    免费注册
                </span>
            </div>

        </div>


        <div class="service-wrap" v-if="serviceShow" :style="serviceWrapStyle">
            <div class="wechat-wrap">
                <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/wechat-fill.png"
                    alt="">
                微信咨询

                <img class="qrcode-img-large" src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/service-qrcode.jpg
" alt="" srcset="">
            </div>


        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: 0,
            navDark: false,
            bdImRootTimer: null,
            serviceShow: false,
            serviceWrapStyle: {}
        }
    },
    mounted() {
        window.addEventListener("scroll", this.scrolling);

        this.fetctBDImRoot()
    },
    methods: {
        // 查询百度客服元素位置，并插入我司客服二维码元素
        fetctBDImRoot() {
            this.bdImRootTimer = setInterval(() => {
                if (document.querySelector('#aff-im-root .embed-icon')) {
                    clearInterval(this.bdImRootTimer)
                    let offsetHeight = document.querySelector('#aff-im-root .embed-icon').offsetHeight || 0
                    let offsetTop = document.querySelector('#aff-im-root .embed-icon').offsetTop + offsetHeight / 2 + 20
                    // let offsetLeft = document.querySelector('#aff-im-root .embed-icon').offsetLeft
                    console.log(offsetHeight)
                    console.log(offsetTop)
                    this.serviceWrapStyle = {
                        'top': offsetTop + 'px',
                        'right': '3px'
                    }
                    this.serviceShow = true
                }
            }, 500)
        },
        scrolling() {
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;

            if (scrollTop > 50) {
                this.navDark = true
            } else {
                this.navDark = false
            }
        }
    },
    computed: {
        logoColor() {
            let path = this.$route.path
            if (this.navDark) {
                return 'white';
            }
            if (path.indexOf('xzwm') >= 0 || path.indexOf('yingxiao') >= 0 || path.indexOf('cpjg') >= 0) {
                return 'blue';
            }
            if (path.indexOf('home') >= 0 || path == '/' || this.activeIndex == 1 || this.activeIndex == 2) {
                return 'white'
            }
            return 'white'
        }
    },
    watch: {
        '$route.path': {
            immediate: true,
            handler() {
                console.log(this.$route.path)
                let path = this.$route.path
                if (path.indexOf('yingxiao') >= 0) {
                    this.activeIndex = 'yingxiao'
                } else if (path.indexOf('xzwm') >= 0) {
                    this.activeIndex = 'xzwm'
                } else if (path.indexOf('home') >= 0 || path == '/') {
                    this.activeIndex = 'home'
                } else if (path.indexOf('jjfa') >= 0) {
                    this.activeIndex = 'jjfa'
                } else if (path.indexOf('cpjg') >= 0) {
                    this.activeIndex = 'cpjg'
                } else {
                    this.activeIndex = 'product'
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.service-wrap {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 0 3px 1px #e8e8e8;
    padding: 10px;

    .wechat-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        color: #555;
        cursor: pointer;

        >img {
            width: 40px;
            height: auto;
        }

        &:hover {
            .qrcode-img-large {
                display: block !important;
            }
        }

        .qrcode-img-large {
            width: 300px;
            height: auto;
            position: absolute;
            top: -100px;
            left: -310px;
            display: none;
            box-shadow: 0 0 3px 1px #e8e8e8;
        }
    }
}

.nav-container-wrap {
    width: 100vw;
    // min-width: 1280px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    height: 80px;
    // background-color: transparent;
    // background-color: rgba(4, 24, 51, 1);
}

.home-nav-container-wrap {
    position: sticky;
    top: 0;
    left: 0;
}

.nav-container {
    width: 100vw;
    min-width: 1280px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background-color: transparent;
    // background-color: rgba(4, 24, 51, 1);
    position: relative;

    .logo-icon-wrap {
        .logo-icon {
            width: 120px;
            height: auto;
            position: absolute;
            left: 150px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.nav-item {
    position: relative;
}

.yingxiao-badge {
    position: absolute;
    top: 8px;
    right: -15px;
    background-color: #F57927;
    color: #ffffff;
    font-size: 12px;
    height: 16px;
    font-weight: 600;
    line-height: 16px;
    padding: 0 2px;

    &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: -4px;
        transform: rotate(-45deg);
        display: block;
        // background-color: #F57927;
        border-top: 4px solid #F57927;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid transparent;
    }
}

.blue-text a,
.blue-text .nav-container-item {
    color: #02112E;
    font-weight: 500;
}

.dark-theme {
    transition: all .2s;
    background-color: rgba(4, 24, 51, 1);

    .register-btn {
        background-color: #0555FF;
        color: #ffffff;
    }
}

.home-nav-container {
    background-color: rgba(4, 24, 51, 1);
}

.login-wrap {
    position: absolute;
    top: 50%;
    right: 50px;
    font-size: 14px;
    color: #fff;

    transform: translateY(-50%);

    display: flex;
    align-items: center;
}

.login-btn {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    margin-right: 30px;
    width: 96px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 6px;
    cursor: pointer;
}

.login-blue {
    color: #0555FF;
    border-color: #0555FF;
}

.register-btn {
    color: #0555FF;
    background-color: #ffffff;
    // background-color: #0555FF;
    // color: #ffffff;
    // padding: 0 20px;
    width: 127px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    border-radius: 6px;
    cursor: pointer;
}

.home-register-btn {
    background-color: #0555FF;
    color: #ffffff;
}

.login-wrap:hover {
    color: #fff;
}

.nav-container-item {
    display: inline-block;
    color: #fff;
    font-size: 16px;
    margin: 0 30px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    cursor: pointer;
    position: relative;
    text-decoration: none;

    &:hover {
        .nav-expand-wrap {
            transition: height 1s;
            display: block;
            height: auto !important;
        }
    }
}

.nav-container-item-active {
    color: #1e87f0 !important;
}

.nav-container-item::before {
    display: inline-block;
    content: " ";
    width: 0;
    height: 0;
    background: #1e87f0;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
}

.nav-container-item:hover::before {
    display: inline-block;
    content: " ";
    transition: 300ms ease all;
    height: 3px;
    width: 100%;
    background: #1e87f0;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
}

.nav-expand-wrap {
    position: fixed;
    left: 0;
    top: 80px;
    width: 100vw;
    z-index: 99999;
    align-items: center;
    justify-content: center;
    // overflow: hidden;
    display: none;
    background-color: #ffffff;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 10%);
    height: 0;
    overflow: hidden;
    transition: height 1s;

    &::after {
        content: "";
        display: block;
        height: 30px;
        width: 100%;
        opacity: 0;
        position: absolute;
        left: 0;
        top: -30px;
    }

    .nav-column-list {
        padding: 10px 149px 50px;
        display: flex;


        .nav-column {
            display: flex;
            flex-direction: column;
            border-right: 1px solid #ddd;
            width: 280px;
            margin-right: 30px;

            &:last-of-type {
                margin-bottom: 0px;
            }

            .nav-column-title {
                padding-left: 52px;
                font-size: 14px;
                color: #999;
                margin-bottom: 20px;
                line-height: 20px;
                text-align: left;
            }

            .nav-column-link {
                display: flex;
                text-decoration: none !important;
                margin-bottom: 30px;

                &:last-of-type {
                    margin-bottom: 0px;
                }

                .icon {
                    width: 22px;
                    height: 22px;
                }

                .context {

                    margin-left: 8px;
                    text-align: left;

                    .label {
                        font-size: 16px;
                        line-height: 16px;
                        font-weight: 700;
                        color: #333;
                        margin-bottom: 12px;
                    }

                    .desc {
                        color: #666;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }
    }

    >a {
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #000;
        letter-spacing: 1.5px;
        text-align: center;
        margin-bottom: 15px;
        line-height: 20px;
        display: block;
        text-decoration: none;
    }
}

// .nav-expand-wrap:hover {
//     transition: 300ms ease all;
//     height: 42px;
// }

// .nav-expand-wrap-active {
//     transition: 300ms ease all;
//     height: 42px;
// }

.nav-expand-wrap .nav-expand-wrap-item {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    margin-right: 20px;
}
</style>