<template>
    <div class="cpjg-wrap">
        <div class="banner">
            <div class="info">
                <div class="title">产品定价</div>
                <div class="desc">根据您的规模和需求，选中不同的产品套餐</div>
                <div class="chat-btn" @click="handleRegisterJump">查看其他产品价格</div>
                <div class="more-discount track-lxwm-el">申请更多优惠 ></div>
            </div>

            <img class="banner-img"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/cpjg/banner.png" />
        </div>

        <div class="tab-list">
            <div @click="handleTabClick(index)" :class="['tab-item', activeIndex == index ? 'tab-item-active' : '']"
                v-for="(item, index) in tabList" :key="index">{{ item }}</div>
        </div>

        <div class="price-list">
            <div class="price-item" v-for="(item, index) in currentPriceList" :key="index">
                <div class="title">{{ item.title }}</div>
                <div class="desc">{{ item.desc }}</div>

                <div class="info">
                    <div class="label">产品规格</div>
                    <div class="context">{{ item.num }}</div>
                    <div class="label">有效期</div>
                    <div class="context">{{ item.time }}</div>
                </div>

                <div class="price">
                    {{ item.price }}<span class="unit">元/年</span>
                </div>

                <div class="btn" @click="handleRegisterJump">立即购买</div>
            </div>
        </div>

        <div class="column-wrap jrlc-wrap" id="jrlc-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    接入流程
                </div>
                <div class="title-shadow">
                    pick-up
                </div>
            </div>

            <div class="liucheng-list">
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-1.png"
                        alt="">
                    <div class="title">账号注册</div>
                    <div class="desc">点击「免费注册」输入手机号和验证码完成注册</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-2.png"
                        alt="">
                    <div class="title">开通服务</div>
                    <div class="desc">开通相关的功能服务</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-3.png"
                        alt="">
                    <div class="title">开发和联调</div>
                    <div class="desc">获取AppKey/AppSecret进行API调用</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-4.png"
                        alt="">
                    <div class="title">开始使用</div>
                    <div class="desc">开始使用浩鲸物流的功能</div>
                </div>

                <div class="arrow-list">
                    <div class="link-arrow link-arrow-1"></div>
                    <div class="link-arrow link-arrow-2"></div>
                    <div class="link-arrow link-arrow-3"></div>
                </div>

            </div>
            <a href="javascript:void(0)" class="contact-btn track-lxwm-el">联系我们</a>
        </div>

        <ContactWrap />
    </div>
</template>

<script>
import ContactWrap from '@/components/Contact-wrap.vue';
export default {
    data() {
        return {
            activeIndex: 0,
            tabList: [
                '物流轨迹订阅',
                '物流轨迹查询',
                '地图轨迹订阅',
                '地图轨迹查询',
                '时效预测（前）',
                '时效预测（后）',
            ],
            priceList: [
                // 物流轨迹订阅
                [
                    { title: '轨迹订阅', desc: '支持即时查询，为用户提供全流程的物流状态查询服务。', num: '10000次', time: '一年', price: '270.00' },
                    { title: '轨迹订阅', desc: '支持即时查询，为用户提供全流程的物流状态查询服务。', num: '50000次', time: '一年', price: '1150.00' },
                    { title: '轨迹订阅', desc: '支持即时查询，为用户提供全流程的物流状态查询服务。', num: '100000次', time: '一年', price: '2200.00' },
                    { title: '轨迹订阅', desc: '支持即时查询，为用户提供全流程的物流状态查询服务。', num: '200000次', time: '一年', price: '4300.00' },
                    { title: '轨迹订阅', desc: '支持即时查询，为用户提供全流程的物流状态查询服务。', num: '500000次', time: '一年', price: '10600.00' },
                    { title: '轨迹订阅', desc: '支持即时查询，为用户提供全流程的物流状态查询服务。', num: '1000000次', time: '一年', price: '20700.00' },
                ],
                // 物流轨迹查询
                [
                    { title: '物流轨迹查询', desc: '物流信息查询服务，支持实时查询和订阅', num: '10000次', time: '一年', price: '50.00' },
                    { title: '物流轨迹查询', desc: '物流信息查询服务，支持实时查询和订阅', num: '50000次', time: '一年', price: '210.00' },
                    { title: '物流轨迹查询', desc: '物流信息查询服务，支持实时查询和订阅', num: '100000次', time: '一年', price: '400.00' },
                    { title: '物流轨迹查询', desc: '物流信息查询服务，支持实时查询和订阅', num: '200000次', time: '一年', price: '780.00' },
                    { title: '物流轨迹查询', desc: '物流信息查询服务，支持实时查询和订阅', num: '500000次', time: '一年', price: '1900.00' },
                    { title: '物流轨迹查询', desc: '物流信息查询服务，支持实时查询和订阅', num: '1000000次', time: '一年', price: '3750.00' },
                ],
                // 地图轨迹订阅
                [
                    { title: '地图轨迹订阅', desc: '以可视化的形式在地图上直观的展示物流状态', num: '10000次', time: '一年', price: '660.00' },
                    { title: '地图轨迹订阅', desc: '以可视化的形式在地图上直观的展示物流状态', num: '50000次', time: '一年', price: '2800.00' },
                    { title: '地图轨迹订阅', desc: '以可视化的形式在地图上直观的展示物流状态', num: '100000次', time: '一年', price: '5300.00' },
                    { title: '地图轨迹订阅', desc: '以可视化的形式在地图上直观的展示物流状态', num: '200000次', time: '一年', price: '10500.00' },
                    { title: '地图轨迹订阅', desc: '以可视化的形式在地图上直观的展示物流状态', num: '500000次', time: '一年', price: '25800.00' },
                    { title: '地图轨迹订阅', desc: '以可视化的形式在地图上直观的展示物流状态', num: '1000000次', time: '一年', price: '50300.00' },
                ],
                // 地图轨迹查询
                [
                    { title: '地图轨迹查询', desc: '以可视化的形式在地图上直观的展示物流状态', num: '10000次', time: '一年', price: '300.00' },
                    { title: '地图轨迹查询', desc: '以可视化的形式在地图上直观的展示物流状态', num: '50000次', time: '一年', price: '1250.00' },
                    { title: '地图轨迹查询', desc: '以可视化的形式在地图上直观的展示物流状态', num: '100000次', time: '一年', price: '2000.00' },
                    { title: '地图轨迹查询', desc: '以可视化的形式在地图上直观的展示物流状态', num: '200000次', time: '一年', price: '3000.00' },
                    { title: '地图轨迹查询', desc: '以可视化的形式在地图上直观的展示物流状态', num: '500000次', time: '一年', price: '5000.00' },
                    { title: '地图轨迹查询', desc: '以可视化的形式在地图上直观的展示物流状态', num: '1000000次', time: '一年', price: '8000.00' },
                ],
                // 时效预测（前）
                [
                    { title: '时效预测（前）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '10000次', time: '一年', price: '135.00' },
                    { title: '时效预测（前）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '50000次', time: '一年', price: '575.00' },
                    { title: '时效预测（前）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '100000次', time: '一年', price: '1100.00' },
                    { title: '时效预测（前）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '200000次', time: '一年', price: '2150.00' },
                    { title: '时效预测（前）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '500000次', time: '一年', price: '5300.00' },
                    { title: '时效预测（前）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '1000000次', time: '一年', price: '10350.00' },
                ],
                // 时效预测（后）
                [
                    { title: '时效预测（后）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '10000次', time: '一年', price: '135.00' },
                    { title: '时效预测（后）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '50000次', time: '一年', price: '575.00' },
                    { title: '时效预测（后）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '100000次', time: '一年', price: '1100.00' },
                    { title: '时效预测（后）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '200000次', time: '一年', price: '2150.00' },
                    { title: '时效预测（后）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '500000次', time: '一年', price: '5300.00' },
                    { title: '时效预测（后）', desc: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间，精确到小时级', num: '1000000次', time: '一年', price: '10350.00' },
                ],
            ]
        }
    },
    components: {
        ContactWrap
    },
    computed: {
        currentPriceList() {
            return this.priceList[this.activeIndex];
        }
    },
    methods: {
        handleTabClick(index) {
            this.activeIndex = index;
        }
    }
}
</script>

<style lang="scss" scoped>
.cpjg-wrap {
    padding-top: 80px;
    position: relative;

    &:after {
        content: "";
        width: 100%;
        height: 644px;
        background-image: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 53%, #E7F4FF 100%);
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }

    .banner {
        display: flex;
        justify-content: center;

        .info {
            margin-top: 119px;

            .title {
                font-size: 48px;
                font-weight: bold;
            }

            .desc {
                font-size: 16px;
            }

            .chat-btn {
                width: 157px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 16px;
                color: #fff;
                margin-top: 33px;
                background-image: linear-gradient(90deg, #0C8EFF 2%, #0555FF 98%);
                border-radius: 6px;
                cursor: pointer;
            }

            .more-discount {
                font-weight: 500;
                font-size: 16px;
                color: #0555FF;
                margin-top: 17px;
                cursor: pointer;
            }
        }

        .banner-img {
            width: 270px;
            margin-left: 300px;
            margin-top: 92px;
            height: auto;
        }
    }

    .tab-list {
        padding: 0 144px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        box-sizing: border-box;
        margin-top: 59px;
        border-radius: 3.6px;

        .tab-item {
            cursor: pointer;
            width: 188px;
            height: 44px;
            background: #fff;
            line-height: 44px;
            text-align: center;
        }

        .tab-item-active {
            background-color: #0555FF;
            color: #fff;
            border-radius: 3.6px;
        }
    }

    .price-list {
        display: flex;
        width: 1200px;
        margin: 56px auto;
        flex-wrap: wrap;
        justify-content: space-between;


        .price-item {
            background-color: #fff;
            background-image: url("https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/cpjg/chanpin-bg-1.png");
            background-size: 100% 138px;
            background-repeat: no-repeat;
            background-position: top;
            width: 366px;
            height: 398px;
            box-sizing: border-box;
            padding: 25px 27px;

            box-shadow: 0 2px 20px 0 #00000014;
            border-radius: 14px;
            margin-bottom: 22px;
            border: 4px solid transparent;
            cursor: pointer;
            position: relative;
            transition: all .2s;

            &:hover {
                border: 4px solid #0555FF;
                transform: translateY(-10px);
            }

            .title {
                font-size: 26px;
                color: #02112E;
                font-weight: bold;
                margin-bottom: 13px;
            }

            .desc {
                width: 316px;
                font-size: 16px;
                color: #414141;
            }

            .info {
                margin-top: 38px;

                .label {
                    color: #414141;
                    font-size: 16px;
                    margin-bottom: 8px;
                }

                .context {
                    color: #131424;
                    font-size: 22px;
                    margin-bottom: 13px;
                }
            }

            .price {
                color: #FA0100;
                font-weight: 500;
                margin-top: 28px;
                font-size: 30px;

                .unit {
                    font-size: 18px;
                }
            }

            .btn {
                position: absolute;
                width: 108px;
                height: 42px;
                line-height: 42px;
                text-align: center;
                color: #0555FF;
                bottom: 34px;
                right: 31px;
                background: #FFFFFF;
                border: 1.3px solid #0555FF;
                border-radius: 6px;
            }
        }
    }

    .jrlc-wrap {

        .liucheng-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 50px;
            position: relative;

            .arrow-list {
                position: absolute;
                top: 53px;
                left: 0;
                display: flex;
                justify-content: space-between;
                padding: 0 265px;
                box-sizing: border-box;
                width: 100%;

                .link-arrow {
                    display: flex;
                    width: 48px;
                    height: 48px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    // background-image: url('../../images/product/wlcx/link-right.png');
                    background-position: center;
                    background-size: 26px 26px;
                    background-repeat: no-repeat;
                    box-shadow: 0 0 12px 0 #0000000d;
                }
            }

            .item {
                width: 277px;
                background-image: linear-gradient(180deg, #FBFCFD 0%, #F6F7FA 100%);
                border-radius: 12px;

                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 49px 0 38px;

                .icon {
                    width: 80px;
                    height: 78px;
                }

                .title {
                    margin-top: 33px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #02112E;
                    letter-spacing: 0;
                    text-align: center;
                }

                .desc {
                    margin-top: 22px;
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #131424;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 20px;
                    width: 227px;
                    height: 40px;
                }
            }
        }

        .contact-btn {
            margin: 50px auto 0;
            width: 201px;
            height: 50px;
            background: #003CE2;
            font-family: PingFangSC-SNaNpxibold;
            font-weight: 600;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 50px;
            text-align: center;
            border-radius: 4px;
            display: block;
            color: #fff;
            box-shadow: 0 0 12px 0 #003CE20d;
        }
    }
}
</style>