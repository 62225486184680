<template>
    <div class="column-wrap gdgn-wrap">
        <div class="shadow-title">
            <div class="title-black">
                更多功能
            </div>
            <div class="title-shadow">
                more
            </div>
        </div>

        <div class="gongneng-list">
            <router-link to="/kdcx?tab=1" class="gongneng-item">
                <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/gongneng-1.png"
                    alt="">
                <div class="label">物流查询</div>
                <div class="desc">支持国内外2000多家快递公司，输入运单编号自动识别物流公司，即时返回物流轨迹。</div>
            </router-link>
            <router-link to="/dtgj" class="gongneng-item">
                <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/gongneng-2.png"
                    alt="">
                <div class="label">订阅</div>
                <div class="desc">
                    汇集国内外2000多家快递公司的物流轨迹数据，以接口形式开放给用户使用，数据内容包括运单号、物流跟踪信息、签收状态等。适用于电商给买家查询物流轨迹、系统内部做物流状态的监控管理、查询物流轨迹详情。</div>
            </router-link>
            <router-link to="/dtgj" class="gongneng-item">
                <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/gongneng-3.png"
                    alt="">
                <div class="label">地图轨迹</div>
                <div class="desc">
                    包裹什么时候能到，是每个用户在平台消费后最关心的事情。结合地图功能，可视化的以接口API与嵌入式页面开放给客户使用，支持顺丰、京东，四通一达等100多家主流快递公司在地图上直观的展示包裹的位置和预计送达时间。
                </div>
            </router-link>
            <router-link to="/sxyc?tab=1" class="gongneng-item">
                <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/gongneng-4.png"
                    alt="">
                <div class="label">时效预测（发货前）</div>
                <div class="desc">预估发货时间主要考虑有些场景因不同时间点对揽收波次会有较大影响，为了更精准的预估建议传递预估时间，支持按照单号和cp查询发货前时效预测。</div>
            </router-link>
            <router-link to="/sxyc?tab=2" class="gongneng-item">
                <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/gongneng-5.png"
                    alt="">
                <div class="label">时效预测（发货后）</div>
                <div class="desc">参与发货的时效预估，主要考虑有些场景需要对当前到达位置与目标地点进行预估轨迹和时效测算，支持按照单号和cp查询发货前时效预测。</div>
            </router-link>
            <router-link  to="/dzmd" class="gongneng-item">
                <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/gongneng-6.png"
                    alt="">
                <div class="label">电子面单</div>
                <div class="desc">
                    面单支持多家快递物流公司单号获取、分拣码返回、面单打印、在线下单发货、通知快递员上门取件等功能，可用于电商平台、自营商城、打单工具、WMS仓储系统等需要发货的场景，可有效提高商家的打印效率，帮助其降低运营成本。
                </div>
            </router-link>
        </div>
    </div>
</template>
<style lang="scss">
.column-wrap {
    padding: 49px 149px 53px;
}

.shadow-title {
    margin: 0 auto;
    position: relative;
    height: 95px;
    white-space: nowrap;

    .title-black {
        font-size: 36px;
        color: #000;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }

    .title-shadow {
        font-family: SourceHanSansCN-Bold;
        font-weight: 700;
        font-size: 70px;
        color: #E7E7E7;
        text-align: center;
        position: absolute;
        top: -25%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
}

.gdgn-wrap {
    background: #F7F9FC;



    .gongneng-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .gongneng-item {
            width: 368px;
            height: 222px;
            margin-bottom: 20px;
            box-sizing: border-box;
            position: relative;
            text-align: left;
            padding-left: 20px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            overflow: hidden;
            border-radius: 10px;

            &::after {
                content: "";
                display: block;
                height: 100%;
                width: 100%;
                background-image: linear-gradient(180deg, #05367800 0%, #042e684f 44%, #021943d4 100%);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 100;
            }

            img {
                width: 368px;
                height: 222px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 99;
            }

            .label {
                margin-bottom: 10px;
                font-size: 20px;
                color: #fff;
                z-index: 999;
            }

            .desc {
                font-size: 14px;
                color: #fff;
                z-index: 999;
                height: 0;
                overflow: hidden;
            }

            &:hover {
                .desc {
                    height: 100px;
                    transition: all .3s;
                }
            }
        }
    }
}
</style>