<template>
    <div class="jjfa-wrap">
        <div class="banner-wrap">
        </div>

        <div class="container">
            <div class="title">物流服务中的问题</div>
            <div class="tab-list">
                <div class="item">
                    <div class="top">
                        <img class="icon"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/jjfa/icon1.png" />
                        <div class="label">
                            快递无法追踪，体验差</div>
                        <div class="desc">客户在平台购物后，不能及时获得物快递信息，容易导致退款或投诉</div>
                    </div>
                </div>

                <div class="item">
                    <div class="top">
                        <img class="icon"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/jjfa/icon2.png" />
                        <div class="label">
                            问题件难发现，效率低</div>
                        <div class="desc">快递监管滞后导致问题件发现慢、触达慢、解决慢、异常件跟踪处理效率低</div>
                    </div>
                </div>

                <div class="item">
                    <div class="top">
                        <img class="icon"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/jjfa/icon3.png" />
                        <div class="label">
                            物流信息咨询，成本高</div>
                        <div class="desc">快递到哪啦？客服人员面临大量、重复的快递物流咨询问题，服务成本高</div>
                    </div>
                </div>

            </div>
            <img class="banner"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/jjfa/banner2.jpeg" />
            <div class="title">实现效果</div>
            <img class="banner"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/jjfa/banner3.jpeg" />

            <div class="contact-wrap">
                <div class="left" v-if="false">
                    <h2>咨询专家</h2>
                    <div class="sub-title">留言免费获取解决方案</div>

                    <div class="label">您的称呼</div>
                    <input class="input-comp" v-model="userName" />
                    <div class="label">您的电话</div>
                    <input class="input-comp" v-model="userPhone" />
                    <div class="label">公司名称</div>
                    <input class="input-comp" v-model="companyName" />
                    <div class="label">您的职位</div>
                    <input class="input-comp" v-model="userJob" />

                    <div class="submit-btn" @click="handleSubmit">提交</div>
                </div>

                <div class="left">
                    <CommonForm />
                </div>



                <img class="banner"
                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/jjfa/banner4.jpeg" />
            </div>
        </div>

    </div>
</template>
<script>
import CommonForm from '../../components/CommonForm-large.vue'
import { saveContact } from '../../api/index.js'
export default {
    components: {
        CommonForm
    },
    data() {
        return {
            userName: '',
            userPhone: '',
            companyName: '',
            userJob: '',
        }
    },
    methods: {
        handleSubmit() {
            const params = {
                userName: this.userName,
                userPhone: this.userPhone,
                companyName: this.companyName,
                userJob: this.userJob,
            }
            console.log(params)

            saveContact(params).then(res => {
                console.log(res)

                if (res.code == 500) {
                    this.$refs.toastRef.show(res.msg)
                } else {
                    this.$refs.toastRef.show("提交成功")
                    this.userName = '';
                    this.userPhone = '';
                    this.companyName = '';
                    this.userJob = '';
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>