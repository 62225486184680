<template>
    <div class="footer-wrap">
        <div class="top">©鲲众云计算科技（苏州）有限公司版权所有</div>
        <div class="bottom">
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">苏ICP备2021023875号</a>
        </div>
    </div>
</template>
<style lang="scss" scoped>

.footer-wrap {
    width: 100%;
    box-sizing: border-box  ;
    font-size: 12px;
    margin-top: 50px;
    .top {
        height: 40px;
        background-color: #000;
        color: #aaa;
        text-align: center;
        line-height: 40px;
    }
    .bottom {
        padding: 5px 0;

        text-align: center;
        a {
            color: #333;

        }
    }
}
</style>