import { createRouter, createWebHashHistory } from "vue-router";
import Home from '../views/home/index.vue'
import Lxwm from '../views/lxwm/index.vue'
import Jjfa from '../views/jjfa/jjfa-index.vue'
import Kdcx from '../views/kdcx/kdcx-index.vue'
import Dtgj from '../views/dtgj/dtgj-index.vue'
import Sxyc from '../views/sxyc/sxyc-index.vue'
import Dzmd from '../views/dzmd/dzmd-index.vue'
import Gjwldy from '../views/gjwldy/gjwldy-index.vue'
import Dhsb from '../views/dhsb/dhsb-index.vue'
import Wlycyj from '../views/wlycyj/wlycyj-index.vue'
import Ddlj from '../views/ddlj/ddlj-index.vue'
import Grjj from '../views/grjj/grjj-index.vue'
import Gjdzk from '../views/gjdzk/gjdzk-index.vue'
import Xzwm from '../views/xzwm/xzwm-index.vue'
import Yingxiao from '../views/yingxiao/yingxiao-index.vue'
import Cpjg from '../views/cpjg/cpjg-index.vue'


const routes = [
    {
        path: '/',
        name: Home,
        component: Home,
        meta: { title: "【物流云API】快递单号查询接口-快递电子面单-全球物流数据服务商-鲲众云" }
    },
    {
        path: '/home',
        name: Home,
        component: Home,
        meta: { title: "【物流云API】快递单号查询接口-快递电子面单-全球物流数据服务商-鲲众云" }
    },
    {
        path: '/lxwm',
        name: Lxwm,
        component: Lxwm,
        meta: { title: "联系我们-鲲众云" }
    },
    {
        path: '/jjfa',
        name: Jjfa,
        component: Jjfa,
        meta: { title: "电商平台解决方案-鲲众云" }
    },
    {
        path: '/kdcx',
        name: Kdcx,
        component: Kdcx,
        meta: { title: "浩鲸物流轨迹地图-鲲众云" }
    },
    {
        path: '/dtgj',
        name: Dtgj,
        component: Dtgj,
        meta: { title: "地图轨迹-鲲众云" }
    },
    {
        path: '/sxyc',
        name: Sxyc,
        component: Sxyc,
        meta: { title: "时效预测-鲲众云" }
    },
    {
        path: '/dzmd',
        name: Dzmd,
        component: Dzmd,
        meta: { title: "电子面单-鲲众云" }
    },
    {
        path: '/gjwldy',
        name: Gjwldy,
        component: Gjwldy,
        meta: { title: "国际物流订阅-鲲众云" }
    },
    {
        path: '/dhsb',
        name: Dhsb,
        component: Dhsb,
        meta: { title: "单号识别-鲲众云" }
    },
    {
        path: '/wlycyj',
        name: Wlycyj,
        component: Wlycyj,
        meta: { title: "物流预测预警-鲲众云" }
    },
    {
        path: '/ddlj',
        name: Ddlj,
        component: Ddlj,
        meta: { title: "订单拦截-鲲众云" }
    },
    {
        path: '/grjj',
        name: Grjj,
        component: Grjj,
        meta: { title: "商家寄件-鲲众云" }
    },
    {
        path: '/gjdzk',
        name: Gjdzk,
        component: Gjdzk,
        meta: { title: "国际地址库-鲲众云" }
    },
    {
        path: '/xzwm',
        name: Xzwm,
        component: Xzwm,
        meta: { title: "选择我们-鲲众云" }
    },
    {
        path: '/yingxiao',
        name: Yingxiao,
        component: Yingxiao,
        meta: { title: "新用户注册即送大礼包-鲲众云" }
    },
    {
        path: '/cpjg',
        name: Cpjg,
        component: Cpjg,
        meta: { title: "产品价格-鲲众云" }
    },

]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
            }
        }
        // 始终滚动到顶部
        return { top: 0 }
    },
})

router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
    if (to.meta.title) {//判断是否有标题
        // @ts-ignore
        document.title = to.meta.title
    }
    // if (!to.query.bd_vid) {
    if (from.query.bd_vid && !to.query.bd_vid) {
        next({
            path: to.path,
            query: {
                ...to.query,
                bd_vid: from.query.bd_vid
            }
        })
    } else {
        next()//执行进入路由，如果不写就不会进入目标页
    }
})

export default router