<template>
    <div class="product-wrap wlcx-wrap">
        <div class="banner">
            <img class="banner"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/banner-1.png"
                alt="">
            <div class="content">
                <div class="title">商家寄件</div>
                <div class="desc">为寄件用户提供一键下单到快递员，并在2小时上门取件的寄件服务。适用于在线寄件、退换货等业务。</div>
                <div class="btn-list">
                    <span @click="handleRegisterJump" class="btn-zixun">免费体验</span>
                    <span @click="handlePriceJump" class="btn-tiyan">查看价格</span>
                </div>
            </div>
        </div>

        <div class="nav-wrap">
            <div class="item nav-item">商家寄件 <img class="icon"
                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/arrow-down.png"
                    alt="">
            </div>
            <router-link to="#cpgn-wrap" class="item item-active">核心功能</router-link>
            <router-link to="#cpys-wrap" class="item">产品优势</router-link>
            <router-link to="#yycj-wrap" class="item">应用场景</router-link>
            <router-link to="#jrlc-wrap" class="item">接入流程</router-link>
            <!-- <div class="item">接入流程</div> -->
        </div>

        <div class="cpgn-wrap" id="cpgn-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    核心功能
                </div>
                <div class="title-shadow">
                    functional
                </div>
            </div>

            <div class="content">
                <div class="left">
                    <div class="item">
                        <img class="logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/hexin-1.png"
                            alt="">
                        <div class="title">自动填写信息</div>
                        <div class="desc">扫描识别收件人姓名、地址、手机号码等，自动分类填写。</div>
                    </div>

                    <div class="item">
                        <img class="logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/hexin-2.png"
                            alt="">
                        <div class="title">一键退货</div>
                        <div class="desc">在app或后台一键退货，不用线下找快递员，不用苦苦等待即可完成发货。</div>
                    </div>

                    <div class="item">
                        <img class="logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/hexin-3.png"
                            alt="">
                        <div class="title">在线支付运费</div>
                        <div class="desc">快递员上门取件与在线收款，通过APP在线支付运费。</div>
                    </div>

                    <div class="item">
                        <img class="logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/hexin-4.png"
                            alt="">
                        <div class="title">预约上门时间</div>
                        <div class="desc">上门前电话联络，在两小时后内上门揽收后回到网点将包裹发送分拨中心。</div>
                    </div>

                    <div class="item">
                        <img class="logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/hexin-5.png"
                            alt="">
                        <div class="title">智能调度订单</div>
                        <div class="desc">根据不同时效产品，智能分派服务的快递公司。</div>
                    </div>

                    <div class="item">
                        <img class="logo"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/hexin-6.png"
                            alt="">
                        <div class="title">运费险赔付</div>
                        <div class="desc">服务过程中因物流公司原因出现快递丢失、破损等情况，提供保障赔付。</div>
                    </div>

                </div>
                <div class="right">
                    <img class="phone"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/phone.png"
                        alt="">
                </div>
            </div>


        </div>

        <div class="column-wrap cpys-wrap" id="cpys-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    产品优势
                </div>
                <div class="title-shadow">
                    advantages
                </div>
            </div>

            <div class="youshi-list">
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/youshi-1.png"
                        alt="">
                    <div class="title">上门时效有保障</div>
                    <div class="desc">买家一键退货，实时接单响应，预约时间上门，当天揽件转运。</div>
                </div>

                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/youshi-2.png"
                        alt="">
                    <div class="title">覆盖全国城市</div>
                    <div class="desc">收件城市覆盖300+，多家快递提供服务，根据不同时效产品，智能分派快递公司完成服务。</div>
                </div>

                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/youshi-3.png"
                        alt="">
                    <div class="title">可在线支付运费</div>
                    <div class="desc">寄件用户在线支付运费到电商平台，全国统一分区定价，支持运费险。</div>
                </div>

                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/youshi-4.png"
                        alt="">
                    <div class="title">降低运维成本</div>
                    <div class="desc">强大的物流调度中台，所有订单、服务异常通过在线系统提交和反馈，减少线下客服咨询量。</div>
                </div>

            </div>
        </div>

        <div class="yycj-wrap" id="yycj-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    应用场景
                </div>
                <div class="title-shadow">
                    scenario
                </div>
            </div>

            <div class="changjing-list">
                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/changjing-1.png"
                            alt="">
                        <div class="title">电商退换货</div>
                        <div class="sub-title">e-commerce returns and exchanges</div>
                    </div>
                    <div class="bottom">
                        电商平台、自营电商售后退货退款时，买家通过平台直接预约快递员上门取件，支持在线支付运费。
                    </div>
                </div>

                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/changjing-2.png"
                            alt="">
                        <div class="title">门店发货调拨</div>
                        <div class="sub-title">Shipment and allocation of stores</div>
                    </div>
                    <div class="bottom">
                        采用信息化手段实现门店间货物的快速调拨，解决店间调拨数量多、分布广、沟通慢、管理累、花费高等诸多问题，解决销售需求和库存的不平衡。
                    </div>
                </div>

                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/changjing-3.png"
                            alt="">
                        <div class="title">二手回收/租赁回收</div>
                        <div class="sub-title">second-hand recycling/rental recycling are respectively</div>
                    </div>
                    <div class="bottom">
                        通过高效的逆向物流、上门取件等基础能力，提供以二手回收、租赁回收场景为核心的解决方案，通过平台直接预约快递员上门回收，支持在线支付运费。
                    </div>
                </div>
            </div>
        </div>

        <div class="column-wrap jrlc-wrap" id="jrlc-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    接入流程
                </div>
                <div class="title-shadow">
                    pick-up
                </div>
            </div>

            <div class="liucheng-list">
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-1.png"
                        alt="">
                    <div class="title">账号注册</div>
                    <div class="desc">点击「免费注册」输入手机号和验证码完成注册</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-2.png"
                        alt="">
                    <div class="title">开通服务</div>
                    <div class="desc">开通相关的功能服务</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-3.png"
                        alt="">
                    <div class="title">开发和联调</div>
                    <div class="desc">获取AppKey/AppSecret进行API调用</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-4.png"
                        alt="">
                    <div class="title">开始使用</div>
                    <div class="desc">开始使用浩鲸物流的功能</div>
                </div>

                <div class="arrow-list">
                    <div class="link-arrow link-arrow-1"></div>
                    <div class="link-arrow link-arrow-2"></div>
                    <div class="link-arrow link-arrow-3"></div>
                </div>

            </div>
            <a href="javascript:void(0)" class="contact-btn track-lxwm-el">联系我们</a>
        </div>

        <MoreItem />

        <!-- 联系我们 -->
        <div class="column-wrap contact-wrap">
            <div class="contact-content">
                <div class="left">
                    <div class="label">告诉我们您的需求</div>
                    <div class="desc">我们很乐意听取您的需求，并讨论我们如何帮助。请填写右侧表格，安排电话或讨论，并在您方便的时间会面。</div>
                    <div class="dashborder"></div>
                    <div class="label-2">
                        <p>关于您的预订有任何问题吗？</p>
                        <p>您可以通过以下方式与我们联系：</p>
                    </div>
                    <div class="contact-list">
                        <p>咨询电话：15645112352</p>
                        <p>合作邮箱：1721003310@qq.com</p>
                        <p>办公地址：北京市朝阳区望京绿地中心B座1903</p>
                    </div>
                </div>

                <CommonForm />
            </div>
        </div>
    </div>
</template>

<script>
import CommonForm from '../../components/CommonForm.vue'
import MoreItem from '../../components/MoreItem.vue'
export default {
    components: {
        CommonForm,
        MoreItem
    },
    mounted() {



        // function btnTab1Func() {
        //     document.querySelector('.func-btn-1').classList.add('item-active')
        //     document.querySelector('.func-btn-2').classList.remove('item-active')
        //     document.querySelector('.cpgn-wrap .right .desc').textContent = '输入完整的寄件人、收件人地址信息以及快递单号，可以更准确地查询到包裹的预计到达时间。此时系统会综合考虑各种因素来预估包裹的运输时间。'
        //     document.querySelector('.cpgn-wrap .phone').src = 'https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/sxyc/fangshi-1.png'
        // }

        // function btnTab2Func() {
        //     document.querySelector('.func-btn-1').classList.remove('item-active')
        //     document.querySelector('.func-btn-2').classList.add('item-active')
        //     document.querySelector('.cpgn-wrap .right .desc').textContent = '输入快递单号，即可查询快递的大概到达时间。需要注意的是，基础查询只能提供包裹的大概到达时间，实际情况可能会因天气、道路等因素而有所偏差。'
        //     document.querySelector('.cpgn-wrap .phone').src = 'https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/sxyc/fangshi-2.png'
        // }

        // function getQueryString(name) {
        //     var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        //     var r = window.location.search.substr(1).match(reg);
        //     if (r != null) {
        //         return unescape(r[2]);
        //     }
        //     return null;
        // }

    }
}
</script>

<style lang="scss" scoped>
@import '../../static/style/product.common.scss';

.cpgn-wrap {
    padding: 80px 159px 59px 210px;
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    box-sizing: border-box;

    .content {
        display: flex;
        margin-top: 50px;

        .left {
            width: 720px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .item {
                width: 352px;
                height: 226px;
                background-image: linear-gradient(180deg, #FBFCFD 0%, #F6F7FA 100%);
                border-radius: 20px;
                padding: 36px 26px 22px 31px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .logo {
                    width: 70px;
                    height: auto;
                }

                .title {
                    font-size: 24px;
                    font-weight: bold;
                    color: #000;
                }

                .desc {
                    font-size: 16px;
                }
            }
        }

        .right {
            margin-left: 67px;

            .phone {
                width: 352px;
                height: 712px;
            }
        }
    }
}

.yycj-wrap {
    padding: 49px 149px 53px;

    .changjing-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .changjing-item {
            width: 368px;
            border-radius: 12px;
            overflow: hidden;

            .top {
                height: 222px;
                position: relative;

                >img {
                    width: 100%;
                    height: 100%;
                }

                .title {
                    height: 28px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 42px;
                    left: 20px;
                }

                .sub-title {
                    height: 17px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 12px;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 26px;
                    left: 20px;
                }
            }

            .bottom {
                height: 117px;
                background: #F7F8FB;
                padding: 20px;
                box-sizing: border-box;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 14px;
                color: #828282;
            }
        }
    }
}

.cpys-wrap {
    background-image: url("https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/grjj/youshi-bg.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-color: #F7F9FC;

    .youshi-list {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item {
            box-sizing: border-box;
            width: 272px;
            height: 304px;
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            box-shadow: 0 0 12px 0 #0000000d;
            border-radius: 12px;
            padding: 30px 28px;
            display: flex;
            flex-direction: column;
            align-items: center;


            .icon {
                width: 54px;
                height: 54px;
                margin-bottom: 22px;
            }

            .title {
                font-size: 24px;
                color: #000;
                font-weight: bold;
            }

            .desc {
                font-weight: 400;
                font-size: 16px;
                color: #02112E;
                margin-top: 28px;
            }
        }
    }
}

.yhjs-wrap {
    background: #F7F9FC;

    .juese-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .item {
            width: 368px;
            background: #FFFFFF;
            box-shadow: 0 0 8px 0 #00000005;
            border-radius: 12px;
            padding: 38px 29px;
            box-sizing: border-box;
            transition: all .3s;

            &:hover {

                .title,
                .desc,
                .link {
                    color: #ffffff !important;
                }

                background-color: #003CE2 !important;
            }

            .icon {
                width: 63px;
                height: 62px;
            }

            .icon-w {
                width: 63px;
                height: 62px;
                display: none;
            }

            &:hover {
                .icon {
                    display: none;
                }

                .icon-w {
                    display: block;
                }
            }

            .title {
                margin-top: 34px;
                line-height: 42px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 30px;
                color: #02112E;
                letter-spacing: 0;
            }

            .desc {
                margin-top: 17px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 16px;
                color: #131424;
                letter-spacing: 0;
                line-height: 24px;
            }

            .link {
                margin-top: 20px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 15px;
                color: #003CE2;
                letter-spacing: 0;
                display: flex;
                align-items: center;

                .link-icon {
                    margin-left: 3px;
                    width: 15px;
                    height: 15px;
                }

                .link-icon-w {
                    display: none;
                    margin-left: 3px;
                    width: 15px;
                    height: 15px;
                }
            }

            &:hover {
                .link-icon {
                    display: none;
                }

                .link-icon-w {
                    display: block;
                }
            }
        }
    }
}

.jrlc-wrap {

    .liucheng-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        position: relative;

        .arrow-list {
            position: absolute;
            top: 53px;
            left: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 265px;
            box-sizing: border-box;
            width: 100%;

            .link-arrow {
                display: flex;
                width: 48px;
                height: 48px;
                background: #FFFFFF;
                border-radius: 50%;
                // background-image: url('../../images/product/wlcx/link-right.png');
                background-position: center;
                background-size: 26px 26px;
                background-repeat: no-repeat;
                box-shadow: 0 0 12px 0 #0000000d;
            }
        }

        .item {
            width: 277px;
            background-image: linear-gradient(180deg, #FBFCFD 0%, #F6F7FA 100%);
            border-radius: 12px;

            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 49px 0 38px;

            .icon {
                width: 80px;
                height: 78px;
            }

            .title {
                margin-top: 33px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 20px;
                color: #02112E;
                letter-spacing: 0;
                text-align: center;
            }

            .desc {
                margin-top: 22px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #131424;
                letter-spacing: 0;
                text-align: center;
                line-height: 20px;
                width: 227px;
                height: 40px;
            }
        }
    }

    .contact-btn {
        margin: 50px auto 0;
        width: 201px;
        height: 50px;
        background: #003CE2;
        font-family: PingFangSC-SNaNpxibold;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        border-radius: 4px;
        display: block;
        color: #fff;
        box-shadow: 0 0 12px 0 #003CE20d;
    }
}
</style>