<template>
    <div class="contact-wrap">

        <div class="left">
            <router-link class="nav-column-link" to="/kdcx?tab=1">
                产品服务
            </router-link>

            <router-link class="nav-column-link" to="/jjfa">
                解决方案
            </router-link>

            <a class="nav-column-link" href="https://www.yuque.com/u34567120/gk2t1c" target="_blank">
                接口API文档
            </a>
        </div>

        <div class="contact-info">
            <div class="title">联系我们</div>

            <div class="label">办公地址</div>
            <div class="context">北京市朝阳区望京绿地中心B座1903</div>

            <div class="label">咨询电话</div>
            <div class="context">15645112352</div>

            <div class="label">商务合作邮箱</div>
            <div class="context">1721003310@qq.com</div>
        </div>

        <CommonForm :title-show="false" btn-size="mid" width="511px" bg="transparent" />
    </div>
</template>

<script>
import CommonForm from './CommonForm.vue'
export default {
    components: {
        CommonForm
    }
}
</script>
<style lang="scss" scoped>
.contact-wrap {
    padding: 80px 159px 59px 210px;
    display: flex;

    .left {
        .nav-column-link {
            display: block;
            font-weight: 500;
            color: #000;
            margin-bottom: 17px;
            font-size: 16px;
        }
    }

    .contact-info {
        margin-left: 64px;
        margin-right: auto;

        .title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 17px;
        }

        .label {
            font-size: 14px;
            line-height: 22px;
            color: #7E8085;
            margin-bottom: 7px;
        }
        .context {
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 10px;
        }
    }
}
</style>