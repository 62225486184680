export const mixin = {
  mounted() {
    let vm = this
    // 点击跳转联系我们
    document.querySelectorAll('.track-lxwm-el').forEach(el => {
      el.addEventListener("click", function () {
        console.log("track-lxwm-el")
        let path = location.pathname
        if (window._hmt) {
          // window._hmt.push(['_trackEvent', 'link-lxwm', 'click', path, 1]);
          window._hmt.push(['_trackEvent', el.innerText, 'click', path, 1]);
        }

        if (document.querySelector('#nb_invite_ok')) {
          document.querySelector('#nb_invite_ok').click()
        } else if (document.querySelector('ins')) {
          document.querySelector('ins .embed-icon-default').click()
        } else {
          // location.href = '/lxwm'
          vm.$router.push('/xzwm')
        }
      });
    })

    // 点解跳转登录
    // const handleLoginTrack = () => {
    //   console.log('a')
    //   let path = location.pathname
    //   window._hmt.push(['_trackEvent', 'link-login', 'click', path, 1]);
    // }
    // document.querySelectorAll('.track-login-el').forEach(el => {
    //   el.removeEventListener("click", handleLoginTrack);
    //   el.addEventListener("click", handleLoginTrack);
    // })

    // this.$nextTick(() => {
    //   document.getElementById('track-login-el').addEventListener('click', function (e) {
    //     console.log(e)
    //   })
    // })

    // 点解跳转注册
    // document.querySelectorAll('.track-register-el').forEach(el => {
    //   el.addEventListener("click", function () {
    //     let path = location.pathname
    //     window._hmt.push(['_trackEvent', 'link-register', 'click', path, 1]);
    //   });
    // })
  },
  methods: {
    // 跳转后台管理登录页
    handleLoginJump(e) {
      console.log(this.$route)
      let path = this.$route.path
      window._hmt.push(['_trackEvent', 'link-register', 'click', path, 1]);
      const context = e.target.innerText
      const pathNew = path = path.slice(1, path.length)
      const bdVid = this.$route.query.bd_vid
      if (bdVid) {
        window.open('https://admin.kzcloud.cn/background/login?path=' + pathNew + '&context=' + context + '&bd_vid=' + bdVid);
      } else {
        window.open('https://admin.kzcloud.cn/background/login?path=' + pathNew + '&context=' + context);
      }

    },
    // 跳转后台管理注册页
    handleRegisterJump(e) {
      let path = this.$route.path
      window._hmt.push(['_trackEvent', 'link-register', 'click', path, 1]);
      const context = e.target.innerText
      const pathNew = path = path.slice(1, path.length)
      const bdVid = this.$route.query.bd_vid
      
      if (bdVid) {
        window.open('https://admin.kzcloud.cn/background/register?path=' + pathNew + '&context=' + context + '&bd_vid=' + bdVid);
      } else {
        window.open('https://admin.kzcloud.cn/background/register?path=' + pathNew + '&context=' + context);
      }
    },
    // 跳转查看价格页面
    handlePriceJump() {
      this.$router.push('/cpjg');
    },
  }
}