<template>
    <div class="common-form">
        <div class="label">咨询专家</div>

        <div class="input-item">
            <div class="input-label">您的称呼*</div>
            <input type="text" v-model="userName">
        </div>

        <div class="input-item">
            <div class="input-label">您的电话*</div>
            <input type="text" v-model="userPhone">
        </div>

        <div class="input-item">
            <div class="input-label">公司名称*</div>
            <input type="text" v-model="companyName">
        </div>

        <div class="input-item">
            <div class="input-label">您的职位*</div>
            <input type="text" v-model="userJob">
        </div>

        <div class="submit-btn" @click="handleSubmit">
            提交
        </div>

        <ToastComp ref="toastRef" />
    </div>
</template>
<script>
import {
    saveContact
} from '../api/index'
import ToastComp from './ToastComp.vue';
export default {
    components: {
        ToastComp
    },
    data() {
        return {
            userName: '',
            userPhone: '',
            companyName: '',
            userJob: '',
        }
    },
    methods: {
        handleSubmit() {
            const params = {
                userName: this.userName,
                userPhone: this.userPhone,
                companyName: this.companyName,
                userJob: this.userJob,
            }
            console.log(params)

            saveContact(params).then(res => {
                if (res.code == 500) {
                    this.$refs.toastRef.show(res.msg)
                } else {
                    this.$refs.toastRef.show("提交成功")
                    this.userName = '';
                    this.userPhone = '';
                    this.companyName = '';
                    this.userJob = '';
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.common-form {
    width: 100%;
    height: 427px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 26px 27px;
    margin: 0 auto;

    .label {
        font-family: DingTalk-JinBuTi;
        font-weight: JinBuTi;
        font-size: 24.72px;
        color: #02112E;
        letter-spacing: 0.18px;
        line-height: 31.78px;
        margin-bottom: 22px;
    }

    .input-item {
        height: 50px;
        padding-left: 26px;
        padding-right: 26px;
        display: flex;
        align-items: center;
        background-color: rgba($color: #377CFD, $alpha: .05);
        margin-bottom: 14px;

        >input {
            flex-grow: 1;
            display: block;
            margin-left: 5px;
            background-color: transparent;
            border: none;
            outline: none;
        }
    }

    .submit-btn {
        width: 100%;
        height: 50px;
        background-image: linear-gradient(90deg, #0C8EFF 2%, #0555FF 98%);
        border-radius: 6px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
    }

    .input-label {
        opacity: 0.8;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #02112E;
        letter-spacing: 0;
        line-height: 28px;
    }
}
</style>