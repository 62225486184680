<template>
    <div class="product-wrap wlcx-wrap">
        <div class="banner">
            <img class="banner"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjwldy/banner-1.png"
                alt="">
            <div class="content">
                <div class="title">国际物流订阅</div>
                <div class="desc">支持900+物流商，提供实时查询和单号订阅API接口。稳定高效，为跨境电商平台、独立站、软件服务商提供优质服务</div>
                <div class="btn-list">
                    <span @click="handleRegisterJump" class="btn-zixun">免费体验</span>
                    <span @click="handlePriceJump" class="btn-tiyan">查看价格</span>
                </div>
            </div>
        </div>

        <div class="nav-wrap">
            <div class="item nav-item">国际物流订阅 <img class="icon"
                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/arrow-down.png"
                    alt="">
                <!-- <div class="nav-popup">
                    <router-link to="/kdcx?tab=1">物流信息推送</router-link>
                    <router-link to="/kdcx?tab=2">物流信息查询</router-link>
                    <router-link to="/dtgj">地图轨迹与订阅</router-link>
                    <router-link to="/sxyc">时效预测</router-link>
                    <router-link to="/dzmd">电子面单</router-link>
                    <router-link to="/gjwlgj">国际物流轨迹</router-link>
                </div> -->
            </div>
            <router-link to="#cpgn-wrap" class="item item-active">核心功能</router-link>
            <router-link to="#yycj-wrap" class="item">应用场景</router-link>
            <router-link to="#jrlc-wrap" class="item">接入流程</router-link>
            <!-- <div class="item">接入流程</div> -->
        </div>

        <div class="column-wrap cpgn-wrap" id="cpgn-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    核心功能
                </div>
                <div class="title-shadow">
                    functional
                </div>
            </div>

            <div class="cpgn-list">
                <div class="hexin-item">
                    <div class="info">
                        <div class="title">国际物流服务-实时查询API</div>
                        <div class="desc">支持全球900+物流商，支持API订阅物流并接入跨境电商、独立站、软件服务商的系统，有效减少客服工作量，提升客户满意度</div>

                        <span class="link-btn" @click="handleRegisterJump">免费体验<img class="link-icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/link-right.png"
                                alt=""></span>
                    </div>
                    <img class="hexin-banner"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjwldy/hexin-1.png"
                        alt="">

                </div>

                <div class="hexin-item">
                    <img class="hexin-banner"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjwldy/hexin-2.png"
                        alt="">
                    <div class="info info-2">
                        <div class="title">国际物流服务-物流订阅API</div>
                        <div class="desc">支持全球900+国际物流商，支持物流订阅服务，订阅后如有包裹状态更新会自动推送</div>

                        <span class="link-btn" @click="handleRegisterJump">免费体验<img class="link-icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/link-right.png"
                                alt=""></span>
                    </div>
                    

                </div>
            </div>
        </div>

        <div class="yycj-wrap" id="yycj-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    应用场景
                </div>
                <div class="title-shadow">
                    scenario
                </div>
            </div>

            <div class="changjing-list">
                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjwldy/changjing-1.png"
                            alt="">
                        <div class="title">900+物流商</div>
                        <div class="sub-title">900+ Logistics Providers</div>
                    </div>
                    <div class="bottom">
                        支持超过900家跨境物流商，物流数据查询能力覆盖全球。
                    </div>
                </div>

                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjwldy/changjing-2.png"
                            alt="">
                        <div class="title">实时监控</div>
                        <div class="sub-title">Real-Time Monitoring</div>
                    </div>
                    <div class="bottom">
                        提供自动化解决方案，一次订阅后如有物流更新会实时自动推送，无需返回调用。
                    </div>
                </div>

                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/gjwldy/changjing-3.png"
                            alt="">
                        <div class="title">安全高效</div>
                        <div class="sub-title">Safe and Efficient</div>
                    </div>
                    <div class="bottom">
                        团队拥有超过12年电商经验，具备专业的信息安全机制，数据稳定可靠。
                    </div>
                </div>
            </div>
        </div>

        <div class="column-wrap jrlc-wrap" id="jrlc-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    接入流程
                </div>
                <div class="title-shadow">
                    pick-up
                </div>
            </div>

            <div class="liucheng-list">
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-1.png"
                        alt="">
                    <div class="title">账号注册</div>
                    <div class="desc">点击「免费注册」输入手机号和验证码完成注册</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-2.png"
                        alt="">
                    <div class="title">开通服务</div>
                    <div class="desc">开通相关的功能服务</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-3.png"
                        alt="">
                    <div class="title">开发和联调</div>
                    <div class="desc">获取AppKey/AppSecret进行API调用</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-4.png"
                        alt="">
                    <div class="title">开始使用</div>
                    <div class="desc">开始使用浩鲸物流的功能</div>
                </div>

                <div class="arrow-list">
                    <div class="link-arrow link-arrow-1"></div>
                    <div class="link-arrow link-arrow-2"></div>
                    <div class="link-arrow link-arrow-3"></div>
                </div>

            </div>
            <a href="javascript:void(0)" class="contact-btn track-lxwm-el">联系我们</a>
        </div>

        <MoreItem />

        <!-- 联系我们 -->
        <div class="column-wrap contact-wrap">
            <div class="contact-content">
                <div class="left">
                    <div class="label">告诉我们您的需求</div>
                    <div class="desc">我们很乐意听取您的需求，并讨论我们如何帮助。请填写右侧表格，安排电话或讨论，并在您方便的时间会面。</div>
                    <div class="dashborder"></div>
                    <div class="label-2">
                        <p>关于您的预订有任何问题吗？</p>
                        <p>您可以通过以下方式与我们联系：</p>
                    </div>
                    <div class="contact-list">
                        <p>咨询电话：15645112352</p>
                        <p>合作邮箱：1721003310@qq.com</p>
                        <p>办公地址：北京市朝阳区望京绿地中心B座1903</p>
                    </div>
                </div>

                <CommonForm />
            </div>
        </div>
    </div>
</template>

<script>
import CommonForm from '../../components/CommonForm.vue'
import MoreItem from '../../components/MoreItem.vue'
export default {
    components: {
        CommonForm,
        MoreItem
    },
    mounted() {
        document.querySelectorAll('.cpys-wrap .left .item').forEach((el, index) => {
            console.log(index)
            el.onmouseenter = function (e) {
                document.querySelectorAll('.cpys-wrap .left .item').forEach(el2 => {
                    el2.classList.remove('item-active')
                })
                e.target.classList.add('item-active')
                document.querySelector('.youshi-img').src = `https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/youshi-${index + 1}.png`
            }
        })
    }
}
</script>

<style lang="scss" scoped>
@import '../../static/style/product.common.scss';

.cpgn-wrap {
    padding: 80px 159px 59px 210px;
    box-sizing: border-box;

    .cpgn-list {
        .hexin-item {
            margin-bottom: 30px;
            display: flex;
            align-items: stretch;
            .info {
                flex-grow: 1;
                padding-right: 48px;
                display: flex;
                flex-direction: column;

                .title {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 17px;
                }

                .desc {
                    font-size: 16px;
                    color: #02112E;
                }

                .link-btn {
                    font-size: 15px;
                    color: #003CE2;
                    display: flex;
                    align-items: center;
                    margin-top: auto;
                    // align-self: flex-end;

                    .link-icon {
                        width: 15px;
                        height: 15px;
                        margin-left: 3px;

                    }
                }
            }

            .info-2 {
                padding-right: 0px;
                padding-left: 48px;
            }

            .hexin-banner {
                width: 798px;
                height: 255px;
            }
        }


    }
}

.cpys-wrap {
    display: flex;
    justify-content: space-between;

    .left {
        width: 477px;

        .item {
            width: 477px;
            background: #FFFFFF;
            box-shadow: 0 0 12px 0 #0000000d;
            border-radius: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 23px 15px 26px;
            transition: all .3s;
            cursor: pointer;
            margin-bottom: 10px;

            .context {
                .label {
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #02112E;
                    letter-spacing: 0;
                }

                .desc {
                    margin-top: 10px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 12px;
                    color: #828282;
                    letter-spacing: 0;
                    line-height: 20px;
                    width: 282px;
                }
            }

            .icon {
                width: 15px;
                height: 20px;
            }

            .icon-w {
                display: none;
            }
        }

        .item-active {
            background-color: #0555FF;

            .label,
            .desc {
                color: #fff !important;
            }

            .icon {
                display: none;
            }

            .icon-w {
                width: 15px;
                height: 20px;
                display: block;
            }
        }
    }

    .right {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .youshi-img {
            width: 587px;
            height: 394px;
            margin: 80px auto 0;
        }
    }
}

.yycj-wrap {
    padding: 49px 149px 53px;

    .changjing-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .changjing-item {
            width: 368px;
            border-radius: 12px;
            overflow: hidden;

            .top {
                height: 222px;
                position: relative;

                >img {
                    width: 100%;
                    height: 100%;
                }

                .title {
                    height: 28px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 42px;
                    left: 20px;
                }

                .sub-title {
                    height: 17px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 12px;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 26px;
                    left: 20px;
                }
            }

            .bottom {
                height: 117px;
                background: #F7F8FB;
                padding: 20px;
                box-sizing: border-box;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 14px;
                color: #828282;
            }
        }
    }
}


.jrlc-wrap {

    .liucheng-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        position: relative;

        .arrow-list {
            position: absolute;
            top: 53px;
            left: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 265px;
            box-sizing: border-box;
            width: 100%;

            .link-arrow {
                display: flex;
                width: 48px;
                height: 48px;
                background: #FFFFFF;
                border-radius: 50%;
                // background-image: url('../../images/product/wlcx/link-right.png');
                background-position: center;
                background-size: 26px 26px;
                background-repeat: no-repeat;
                box-shadow: 0 0 12px 0 #0000000d;
            }
        }

        .item {
            width: 277px;
            background-image: linear-gradient(180deg, #FBFCFD 0%, #F6F7FA 100%);
            border-radius: 12px;

            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 49px 0 38px;

            .icon {
                width: 80px;
                height: 78px;
            }

            .title {
                margin-top: 33px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 20px;
                color: #02112E;
                letter-spacing: 0;
                text-align: center;
            }

            .desc {
                margin-top: 22px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #131424;
                letter-spacing: 0;
                text-align: center;
                line-height: 20px;
                width: 227px;
                height: 40px;
            }
        }
    }

    .contact-btn {
        margin: 50px auto 0;
        width: 201px;
        height: 50px;
        background: #003CE2;
        font-family: PingFangSC-SNaNpxibold;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        border-radius: 4px;
        display: block;
        color: #fff;
        box-shadow: 0 0 12px 0 #003CE20d;
    }
}</style>