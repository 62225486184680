<template>
    <div class="product-wrap wlcx-wrap">
        <div class="banner">
            <img class="banner"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/banner-1.png"
                alt="">
            <div class="content">
                <div class="title">电子面单</div>
                <div class="desc">电子面单API服务提供了获取物流单号、打印电子面单的功能，满足企业的快速打单、回传物流单号的需求</div>
                <div class="btn-list">
                    <span @click="handleRegisterJump" class="btn-zixun">免费体验</span>
                    <span @click="handlePriceJump" class="btn-tiyan">查看价格</span>
                </div>
            </div>
        </div>

        <div class="nav-wrap">
            <div class="item nav-item">电子面单 <img class="icon"
                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/arrow-down.png"
                    alt="">
                <div class="nav-popup">
                    <router-link to="/kdcx?tab=1">物流信息推送</router-link>
                    <router-link to="/kdcx?tab=2">物流信息查询</router-link>
                    <router-link to="/dtgj">地图轨迹与订阅</router-link>
                    <router-link to="/sxyc">时效预测</router-link>
                    <router-link to="/dzmd">电子面单</router-link>
                    <router-link to="/gjwlgj">国际物流轨迹</router-link>
                </div>
            </div>
            <router-link to="#cpgn-wrap" class="item item-active">产品功能</router-link>
            <router-link to="#cpys-wrap" class="item">产品优势</router-link>
            <router-link to="#yycj-wrap" class="item">应用场景</router-link>
            <router-link to="#jrlc-wrap" class="item">接入流程</router-link>
            <!-- <div class="item">接入流程</div> -->
        </div>

        <div class="column-wrap cpgn-wrap" id="cpgn-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    产品功能
                </div>
                <div class="title-shadow">
                    functional
                </div>
            </div>

            <div class="cpgn-list">
                <div class="cpgn-item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/gongneng-1.png"
                        alt="">
                    <div class="label">打印面单</div>
                    <div class="desc">支持40+家主流快递物流公司电子面单打印，大幅提升发货效率。</div>
                </div>

                <div class="cpgn-item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/gongneng-2.png"
                        alt="">
                    <div class="label">运单&订单号自动绑定</div>
                    <div class="desc">省去人工绑定，省钱，省时，省力。</div>
                </div>

                <div class="cpgn-item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/gongneng-3.png"
                        alt="">
                    <div class="label">统一模板尺寸</div>
                    <div class="desc">一联/二联常用尺寸全覆盖、打印过程无需换纸，提高发货速度。</div>
                </div>

                <div class="cpgn-item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/gongneng-4.png"
                        alt="">
                    <div class="label">灵活结算方式</div>
                    <div class="desc">用户可选择现付，到付，月结模式，部分快递支持无需电子面单账号，可直接打印。</div>
                </div>

                <div class="cpgn-item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/gongneng-5.png"
                        alt="">
                    <div class="label">自动上传单号</div>
                    <div class="desc">单号、面单、分拣码自动返回，用户可以同步上传到自己的系统，实现自动上传单号。</div>
                </div>

                <div class="cpgn-item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/gongneng-6.png"
                        alt="">
                    <div class="label">隐私面单</div>
                    <div class="desc">收件人、发件人真实手机号中间四位数加*，确保用户隐私不泄露并可正常收件。</div>
                </div>
            </div>
        </div>

        <div class="column-wrap cpys-wrap" id="cpys-wrap">
            <div class="left">
                <div class="item">
                    <div class="context">
                        <div class="label">打单效率提升4-6倍</div>
                        <div class="desc">极速获取快递单号，打单效率提升4-6倍，每天节约2-3小时。</div>
                    </div>
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-blue.png"
                        alt="">
                    <img class="icon-w"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-white.png"
                        alt="">
                </div>

                <div class="item">
                    <div class="context">
                        <div class="label">对接方便，无需调试打印机</div>
                        <div class="desc">专业的打印控件，90%以上打印机无需调试，直接打印极速发货。</div>
                    </div>
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-blue.png"
                        alt="">
                    <img class="icon-w"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-white.png"
                        alt="">
                </div>

                <div class="item">
                    <div class="context">
                        <div class="label">支持多家快递公司电子面单</div>
                        <div class="desc">支持各家电子面单，60+官方标准模板，统一模板尺寸，打印过程无需换纸。</div>
                    </div>
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-blue.png"
                        alt="">
                    <img class="icon-w"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-white.png"
                        alt="">
                </div>

                <div class="item">
                    <div class="context">
                        <div class="label">单号自动绑定，省去人工成本</div>
                        <div class="desc">订单&运单号自动绑定，方便追踪物流及后续收货管理等售后流程，单号随取随用，部分直营直接打印。</div>
                    </div>
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-blue.png"
                        alt="">
                    <img class="icon-w"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-white.png"
                        alt="">
                </div>

                <div class="item">
                    <div class="context">
                        <div class="label">隐私面单，保护用户隐私</div>
                        <div class="desc">收件人、发件人真实手机号中间四位数加*，确保用户隐私不泄露并可正常收件。</div>
                    </div>
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-blue.png"
                        alt="">
                    <img class="icon-w"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-white.png"
                        alt="">
                </div>
            </div>
            <div class="right">
                <div class="shadow-title">
                    <div class="title-black">
                        产品优势
                    </div>
                    <div class="title-shadow">
                        advantages
                    </div>
                </div>
                <img class="youshi-img"
                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/youshi-1.png"
                    alt="">
            </div>
        </div>

        <div class="yycj-wrap" id="yycj-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    应用场景
                </div>
                <div class="title-shadow">
                    scenario
                </div>
            </div>

            <div class="changjing-list">
                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/changjing-1.png"
                            alt="">
                        <div class="title">电商平台物流管理</div>
                        <div class="sub-title">E-commerce Logistics Management</div>
                    </div>
                    <div class="bottom">
                        电商平台方可以快速建立一套物流管理体系，实现自助快递查询、快递状态自动提醒、物流运力和质量度分析、虚假发货监控、退货险检验等等需求。
                    </div>
                </div>

                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/changjing-2.png"
                            alt="">
                        <div class="title">物流配送信息查询</div>
                        <div class="sub-title">Logistics Delivery Information Query</div>
                    </div>
                    <div class="bottom">
                        买家用户可以随时随地查看包裹实时位置、派送时间、派送人员联系方式，满足买家用户的物流信息获取需求，同时减少大量、重复的快递物流咨询问题。
                    </div>
                </div>

                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/changjing-3.png"
                            alt="">
                        <div class="title">物流系统综合管理</div>
                        <div class="sub-title">Integrated Logistics Management</div>
                    </div>
                    <div class="bottom">
                        卖家用户可以快速处理异常物流事件，如包裹转运监控、客户退换货管理、问题件提醒、签收异常监控、对账结算等等物流异常问题。
                    </div>
                </div>
            </div>
        </div>

        <div class="column-wrap jrlc-wrap" id="jrlc-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    接入流程
                </div>
                <div class="title-shadow">
                    pick-up
                </div>
            </div>

            <div class="liucheng-list">
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-1.png"
                        alt="">
                    <div class="title">账号注册</div>
                    <div class="desc">点击「免费注册」输入手机号和验证码完成注册</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-2.png"
                        alt="">
                    <div class="title">开通服务</div>
                    <div class="desc">开通相关的功能服务</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-3.png"
                        alt="">
                    <div class="title">开发和联调</div>
                    <div class="desc">获取AppKey/AppSecret进行API调用</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-4.png"
                        alt="">
                    <div class="title">开始使用</div>
                    <div class="desc">开始使用浩鲸物流的功能</div>
                </div>

                <div class="arrow-list">
                    <div class="link-arrow link-arrow-1"></div>
                    <div class="link-arrow link-arrow-2"></div>
                    <div class="link-arrow link-arrow-3"></div>
                </div>

            </div>
            <a href="javascript:void(0)" class="contact-btn track-lxwm-el">联系我们</a>
        </div>

        <MoreItem />

        <!-- 联系我们 -->
        <div class="column-wrap contact-wrap">
            <div class="contact-content">
                <div class="left">
                    <div class="label">告诉我们您的需求</div>
                    <div class="desc">我们很乐意听取您的需求，并讨论我们如何帮助。请填写右侧表格，安排电话或讨论，并在您方便的时间会面。</div>
                    <div class="dashborder"></div>
                    <div class="label-2">
                        <p>关于您的预订有任何问题吗？</p>
                        <p>您可以通过以下方式与我们联系：</p>
                    </div>
                    <div class="contact-list">
                        <p>咨询电话：15645112352</p>
                        <p>合作邮箱：1721003310@qq.com</p>
                        <p>办公地址：北京市朝阳区望京绿地中心B座1903</p>
                    </div>
                </div>

                <CommonForm />
            </div>
        </div>
    </div>
</template>

<script>
import CommonForm from '../../components/CommonForm.vue'
import MoreItem from '../../components/MoreItem.vue'
export default {
    components: {
        CommonForm,
        MoreItem
    },
    mounted() {
        document.querySelectorAll('.cpys-wrap .left .item').forEach((el, index) => {
            console.log(index)
            el.onmouseenter = function (e) {
                document.querySelectorAll('.cpys-wrap .left .item').forEach(el2 => {
                    el2.classList.remove('item-active')
                })
                e.target.classList.add('item-active')
                document.querySelector('.youshi-img').src = `https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dzmd/youshi-${index + 1}.png`
            }
        })
    }
}
</script>

<style lang="scss" scoped>
@import '../../static/style/product.common.scss';

.cpgn-wrap {
    padding: 80px 159px 59px 210px;
    box-sizing: border-box;

    .cpgn-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .cpgn-item {
            width: 368px;
            box-sizing: border-box;
            margin-bottom: 18px;
            padding: 36px 26px 26px 34px;
            background-image: linear-gradient(180deg, #FBFCFD 0%, #F6F7FA 100%);
            border-radius: 20px;

            .icon {
                width: 70px;
                height: 70px;
                margin-bottom: 14px;
            }

            .label {
                font-weight: 500;
                font-size: 24px;
                color: #02112E;
                letter-spacing: 0;
                text-align: justify;
                margin-bottom: 18px;
            }

            .desc {
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 16px;
                color: #131424;
                letter-spacing: 0;
                text-align: justify;
            }
        }
    }
}

.cpys-wrap {
    display: flex;
    justify-content: space-between;

    .left {
        width: 477px;

        .item {
            width: 477px;
            background: #FFFFFF;
            box-shadow: 0 0 12px 0 #0000000d;
            border-radius: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 23px 15px 26px;
            transition: all .3s;
            cursor: pointer;
            margin-bottom: 10px;

            .context {
                .label {
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #02112E;
                    letter-spacing: 0;
                }

                .desc {
                    margin-top: 10px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 12px;
                    color: #828282;
                    letter-spacing: 0;
                    line-height: 20px;
                    width: 282px;
                }
            }

            .icon {
                width: 15px;
                height: 20px;
            }

            .icon-w {
                display: none;
            }
        }

        .item-active {
            background-color: #0555FF;

            .label,
            .desc {
                color: #fff !important;
            }

            .icon {
                display: none;
            }

            .icon-w {
                width: 15px;
                height: 20px;
                display: block;
            }
        }
    }

    .right {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .youshi-img {
            width: 587px;
            height: 394px;
            margin: 80px auto 0;
        }
    }
}

.yycj-wrap {
    padding: 49px 149px 53px;

    .changjing-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .changjing-item {
            width: 368px;
            border-radius: 12px;
            overflow: hidden;

            .top {
                height: 222px;
                position: relative;

                >img {
                    width: 100%;
                    height: 100%;
                }

                .title {
                    height: 28px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 42px;
                    left: 20px;
                }

                .sub-title {
                    height: 17px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 12px;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 26px;
                    left: 20px;
                }
            }

            .bottom {
                height: 117px;
                background: #F7F8FB;
                padding: 20px;
                box-sizing: border-box;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 14px;
                color: #828282;
            }
        }
    }
}


.jrlc-wrap {

    .liucheng-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        position: relative;

        .arrow-list {
            position: absolute;
            top: 53px;
            left: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 265px;
            box-sizing: border-box;
            width: 100%;

            .link-arrow {
                display: flex;
                width: 48px;
                height: 48px;
                background: #FFFFFF;
                border-radius: 50%;
                // background-image: url('../../images/product/wlcx/link-right.png');
                background-position: center;
                background-size: 26px 26px;
                background-repeat: no-repeat;
                box-shadow: 0 0 12px 0 #0000000d;
            }
        }

        .item {
            width: 277px;
            background-image: linear-gradient(180deg, #FBFCFD 0%, #F6F7FA 100%);
            border-radius: 12px;

            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 49px 0 38px;

            .icon {
                width: 80px;
                height: 78px;
            }

            .title {
                margin-top: 33px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 20px;
                color: #02112E;
                letter-spacing: 0;
                text-align: center;
            }

            .desc {
                margin-top: 22px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #131424;
                letter-spacing: 0;
                text-align: center;
                line-height: 20px;
                width: 227px;
                height: 40px;
            }
        }
    }

    .contact-btn {
        margin: 50px auto 0;
        width: 201px;
        height: 50px;
        background: #003CE2;
        font-family: PingFangSC-SNaNpxibold;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        border-radius: 4px;
        display: block;
        color: #fff;
        box-shadow: 0 0 12px 0 #003CE20d;
    }
}</style>