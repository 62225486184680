<template>
    <div class="global-layout">
        <HeaderComp />
        <router-view></router-view>
        <FooterComp />
    </div>
</template>

<script>
import HeaderComp from '../components/HeaderComp.vue'
import FooterComp from '../components/FooterComp.vue'
export default {
    components: {
        HeaderComp,
        FooterComp
    }
}
</script>

<style lang="scss" scoped>
.global-layout {
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
}
</style>