<template>
    <div class="product-wrap wlcx-wrap">
        <div class="banner">
            <img class="banner"
                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dtgj/banner-1.png"
                alt="">
            <div class="content">
                <div class="title">地图轨迹与订阅</div>
                <div class="desc">
                    以可视化的形式在地图上直观的展示物流状态、快递位置、运输路线、预计送达时间等内容，支持京东、顺丰、邮政、极兔、德邦、圆通、中通、申通、韵达等100多家主流快递物流公司，可自定义地图样式。</div>
                <div class="btn-list">
                    <span @click="handleRegisterJump" class="btn-zixun">免费体验</span>
                    <span @click="handlePriceJump" class="btn-tiyan">查看价格</span>
                </div>
            </div>
        </div>

        <div class="nav-wrap">
            <div class="item nav-item">地图轨迹 <img class="icon"
                    src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/icons/arrow-down.png"
                    alt="">
                <div class="nav-popup">
                    <router-link to="/kdcx?tab=1">物流信息推送</router-link>
                    <router-link to="/kdcx?tab=2">物流信息查询</router-link>
                    <router-link to="/dtgj">地图轨迹与订阅</router-link>
                    <router-link to="/sxyc">时效预测</router-link>
                    <router-link to="/dzmd">电子面单</router-link>
                    <router-link to="/gjwlgj">国际物流轨迹</router-link>
                </div>
            </div>
            <router-link to="#cpty-wrap" class="item item-active">产品体验</router-link>
            <router-link to="#hxgn-wrap" class="item">核心功能</router-link>
            <router-link to="#yycj-wrap" class="item">应用场景</router-link>
            <router-link to="#cpys-wrap" class="item">产品优势</router-link>
            <router-link to="#yhjs-wrap" class="item">用户角色</router-link>
            <router-link to="#jrlc-wrap" class="item">接入流程</router-link>
        </div>

        <div class="column-wrap cpty-wrap" id="cpty-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    产品体验
                </div>
                <div class="title-shadow">
                    experience
                </div>
            </div>

            <div class="content">
                <div class="left">
                    <div class="desc">
                        无需接入快递公司的接口，只需调用浩鲸物流轨迹订阅接口，即可展示地图轨迹、物流节点信息、预计到达时间、快递单状态等信息，多样化物流信息展示维度，提高客户查询体验。网站/APP/小程序等均可使用。
                    </div>

                    <div class="type-btn type-btn-1">
                        <span class="label">示例：在途中</span>
                        <img class="icon"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-blue.png"
                            alt="" srcset="">
                        <img class="icon-w"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-white.png"
                            alt="" srcset="">
                    </div>

                    <div class="type-btn type-btn-2">
                        <span class="label">示例：派件中</span>
                        <img class="icon"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-blue.png"
                            alt="" srcset="">
                        <img class="icon-w"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-white.png"
                            alt="" srcset="">
                    </div>

                    <div class="type-btn type-btn-3">
                        <span class="label">示例：已签收</span>
                        <img class="icon"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-blue.png"
                            alt="" srcset="">
                        <img class="icon-w"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/arrow-right-white.png"
                            alt="" srcset="">
                    </div>

                    <div class="tips">功能演示仅供体验，如需使用相关服务，请咨询工作人员</div>

                    <span class="link" @click="handleRegisterJump">免费体验 <img class="icon"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/link-right.png"
                            alt="" srcset=""></span>
                </div>

                <div class="right right-1">
                    <div class="card-item card-item-1">
                        <div class="label">动态地图轨迹展示</div>
                        <div class="desc">可动态展示物流运输轨迹，支持嵌入ERP/网页/APP/小程序中，可视化显示商品的实时位置</div>
                    </div>

                    <div class="card-item card-item-2">
                        <div class="label">物流状态及信息</div>
                        <div class="desc">快递物流状态解析，物流状态异常预警、超时预警等，方便用户进行异常件管理</div>
                    </div>

                    <div class="card-item card-item-3">
                        <div class="label">预计送达时间</div>
                        <div class="desc">基于快递公司的运输时效数据，实时和智能地生成较为精准的预计送达时间方便用户制定快递签收等后续工作安排。</div>
                    </div>

                    <div class="border-1"></div>
                    <div class="border-2"></div>

                    <img class="phone"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dtgj/phone-1.png"
                        alt="">
                </div>

                <div class="right right-2 hide-right">
                    <div class="card-item card-item-1">
                        <div class="label">动态地图轨迹展示</div>
                        <div class="desc">可动态展示物流运输轨迹，支持嵌入ERP/网页/APP/小程序中，可视化显示商品的实时位置</div>
                    </div>

                    <div class="card-item card-item-2">
                        <div class="label">物流状态及信息</div>
                        <div class="desc">快递物流状态解析，物流状态异常预警、超时预警等，方便用户进行异常件管理</div>
                    </div>

                    <div class="card-item card-item-3">
                        <div class="label">预计送达时间</div>
                        <div class="desc">基于快递公司的运输时效数据，实时和智能地生成较为精准的预计送达时间方便用户制定快递签收等后续工作安排。</div>
                    </div>

                    <div class="border-1"></div>
                    <div class="border-2"></div>

                    <img class="phone"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dtgj/phone-2.png"
                        alt="">
                </div>

                <div class="right right-3 hide-right">
                    <div class="card-item card-item-1">
                        <div class="label">动态地图轨迹展示</div>
                        <div class="desc">可动态展示物流运输轨迹，支持嵌入ERP/网页/APP/小程序中，可视化显示商品的实时位置</div>
                    </div>

                    <div class="card-item card-item-2">
                        <div class="label">物流状态及信息</div>
                        <div class="desc">快递物流状态解析，物流状态异常预警、超时预警等，方便用户进行异常件管理</div>
                    </div>


                    <div class="border-1"></div>

                    <img class="phone"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dtgj/phone-3.png"
                        alt="">
                </div>
            </div>
        </div>

        <div class="column-wrap hxgn-wrap" id="hxgn-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    核心功能
                </div>
                <div class="title-shadow">
                    functional
                </div>
            </div>

            <div class="content">
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dtgj/hexin-1.png"
                        alt="">
                    <div class="label">订阅查询</div>
                    <div class="desc">企业提交快递单号后，每当快递状态发生变化时，浩鲸物流会自动推送快递当前最新状态及最新地图位置到您的系统中，直到这些运单号的生命周期结束。</div>
                </div>

                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/dtgj/hexin-2.png"
                        alt="">
                    <div class="label">实时查询</div>
                    <div class="desc">用户提交快递单号后，浩鲸物流会实时调取快递公司的单号信息并返回此单号最新的物流状态信息及最新地图位置。（适用于公司网站或APP的最终用户主动点击时才触发调用本接口进行查询）
                    </div>
                </div>
            </div>
        </div>

        <div class="yycj-wrap" id="yycj-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    应用场景
                </div>
                <div class="title-shadow">
                    scenario
                </div>
            </div>

            <div class="changjing-list">
                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/changjing-1.png"
                            alt="">
                        <div class="title">电商平台物流管理</div>
                        <div class="sub-title">E-commerce Logistics Management</div>
                    </div>
                    <div class="bottom">
                        电商平台方可以快速建立一套物流管理体系，实现自助快递查询、快递状态自动提醒、物流运力和质量度分析、虚假发货监控、退货险检验等等需求。
                    </div>
                </div>

                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/changjing-2.png"
                            alt="">
                        <div class="title">物流配送信息查询</div>
                        <div class="sub-title">Logistics Delivery Information Query</div>
                    </div>
                    <div class="bottom">
                        买家用户可以随时随地查看包裹实时位置、派送时间、派送人员联系方式，满足买家用户的物流信息获取需求，同时减少大量、重复的快递物流咨询问题。
                    </div>
                </div>

                <div class="changjing-item">
                    <div class="top">
                        <img src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/changjing-3.png"
                            alt="">
                        <div class="title">物流系统综合管理</div>
                        <div class="sub-title">Integrated Logistics Management</div>
                    </div>
                    <div class="bottom">
                        卖家用户可以快速处理异常物流事件，如包裹转运监控、客户退换货管理、问题件提醒、签收异常监控、对账结算等等物流异常问题。
                    </div>
                </div>
            </div>
        </div>

        <div class="column-wrap cpys-wrap" id="cpys-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    产品优势
                </div>
                <div class="title-shadow">
                    advantages
                </div>
            </div>

            <div class="youshi-list">
                <div class="item">
                    <div class="left">
                        <div class="title">支持2000+物流公司</div>
                        <div class="desc">接入浩鲸物流，即可一次对接2000+家快递物流公司，省去漫长的开发周期。</div>
                    </div>
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/youshi-1.png"
                        alt="">
                </div>

                <div class="item">
                    <div class="left">
                        <div class="title">接口稳定可靠</div>
                        <div class="desc">日查询次数过亿，满足企业大规模物流数据查询需求，接口稳定可靠。</div>
                    </div>
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/youshi-2.png"
                        alt="">
                </div>

                <div class="item">
                    <div class="left">
                        <div class="title">成功率100%</div>
                        <div class="desc">技术水平经历多年实战检验，成功率100%，准确率≥99.99%。</div>
                    </div>
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/youshi-3.png"
                        alt="">
                </div>
            </div>
        </div>

        <div class="column-wrap yhjs-wrap" id="yhjs-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    用户角色
                </div>
                <div class="title-shadow">
                    user role
                </div>
            </div>

            <div class="juese-list">
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/juese-1.png"
                        alt="">
                    <img class="icon-w"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/juese-1-w.png"
                        alt="">
                    <div class="title">买家</div>
                    <div class="desc">
                        <p>· 查看包裹实时位置</p>
                        <p>· 查看包裹预计送达时间</p>
                        <p>· 查看派送人员联系方式</p>
                    </div>
                    <a class="link track-lxwm-el" href="javascript:void(0)">了解更多
                        <img class="link-icon"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/link-right.png"
                            alt="">
                        <img class="link-icon-w"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/link-right-w.png"
                            alt="">
                    </a>

                </div>

                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/juese-2.png"
                        alt="">
                    <img class="icon-w"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/juese-2-w.png"
                        alt="">
                    <div class="title">卖家</div>
                    <div class="desc">
                        <p>· 包裹是否及时转运</p>
                        <p>· 问题件异常件提醒</p>
                        <p>· 包裹签收状态管理</p>
                    </div>
                    <a class="link track-lxwm-el" href="javascript:void(0)">了解更多
                        <img class="link-icon"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/link-right.png"
                            alt="">
                        <img class="link-icon-w"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/link-right-w.png"
                            alt="">
                    </a>
                </div>

                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/juese-3.png"
                        alt="">
                    <img class="icon-w"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/juese-3-w.png"
                        alt="">
                    <div class="title">平台</div>
                    <div class="desc">
                        <p>· 虚假发货监控</p>
                        <p>· 商品退换货管理</p>
                        <p>· 为商家提供物流分析</p>
                    </div>
                    <a class="link track-lxwm-el" href="javascript:void(0)">了解更多
                        <img class="link-icon"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/link-right.png"
                            alt="">
                        <img class="link-icon-w"
                            src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/link-right-w.png"
                            alt="">
                    </a>
                </div>
            </div>
        </div>

        <div class="column-wrap jrlc-wrap" id="jrlc-wrap">
            <div class="shadow-title">
                <div class="title-black">
                    接入流程
                </div>
                <div class="title-shadow">
                    pick-up
                </div>
            </div>

            <div class="liucheng-list">
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-1.png"
                        alt="">
                    <div class="title">账号注册</div>
                    <div class="desc">点击「免费注册」输入手机号和验证码完成注册</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-2.png"
                        alt="">
                    <div class="title">开通服务</div>
                    <div class="desc">开通相关的功能服务</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-3.png"
                        alt="">
                    <div class="title">开发和联调</div>
                    <div class="desc">获取AppKey/AppSecret进行API调用</div>
                </div>
                <div class="item">
                    <img class="icon"
                        src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/product/wlcx/liucheng-4.png"
                        alt="">
                    <div class="title">开始使用</div>
                    <div class="desc">开始使用浩鲸物流的功能</div>
                </div>

                <div class="arrow-list">
                    <div class="link-arrow link-arrow-1"></div>
                    <div class="link-arrow link-arrow-2"></div>
                    <div class="link-arrow link-arrow-3"></div>
                </div>

            </div>
            <a href="javascript:void(0)" class="contact-btn track-lxwm-el">联系我们</a>
        </div>

        <MoreItem />

        <!-- 联系我们 -->
        <div class="column-wrap contact-wrap">
            <div class="contact-content">
                <div class="left">
                    <div class="label">告诉我们您的需求</div>
                    <div class="desc">我们很乐意听取您的需求，并讨论我们如何帮助。请填写右侧表格，安排电话或讨论，并在您方便的时间会面。</div>
                    <div class="dashborder"></div>
                    <div class="label-2">
                        <p>关于您的预订有任何问题吗？</p>
                        <p>您可以通过以下方式与我们联系：</p>
                    </div>
                    <div class="contact-list">
                        <p>咨询电话：15645112352</p>
                        <p>合作邮箱：1721003310@qq.com</p>
                        <p>办公地址：北京市朝阳区望京绿地中心B座1903</p>
                    </div>
                </div>

                <CommonForm />
            </div>
        </div>
    </div>
</template>

<script>
import CommonForm from '../../components/CommonForm.vue'
import MoreItem from '../../components/MoreItem.vue'
export default {
    components:{
        CommonForm,
        MoreItem
    },
    mounted() {
        document.querySelector('.cpty-wrap .content .left .type-btn-1').onmouseenter = function () {
            document.querySelector('.cpty-wrap .content .right-1').classList.remove('hide-right')
            document.querySelector('.cpty-wrap .content .right-2').classList.add('hide-right')
            document.querySelector('.cpty-wrap .content .right-3').classList.add('hide-right')

            document.querySelector('.cpty-wrap .content .type-btn-3').classList.remove('type-btn-active')
            document.querySelector('.cpty-wrap .content .type-btn-2').classList.remove('type-btn-active')
            document.querySelector('.cpty-wrap .content .type-btn-1').classList.add('type-btn-active')
        }

        document.querySelector('.cpty-wrap .content .left .type-btn-2').onmouseenter = function () {
            document.querySelector('.cpty-wrap .content .right-2').classList.remove('hide-right')
            document.querySelector('.cpty-wrap .content .right-1').classList.add('hide-right')
            document.querySelector('.cpty-wrap .content .right-3').classList.add('hide-right')

            document.querySelector('.cpty-wrap .content .type-btn-1').classList.remove('type-btn-active')
            document.querySelector('.cpty-wrap .content .type-btn-3').classList.remove('type-btn-active')
            document.querySelector('.cpty-wrap .content .type-btn-2').classList.add('type-btn-active')
        }

        document.querySelector('.cpty-wrap .content .left .type-btn-3').onmouseenter = function () {
            document.querySelector('.cpty-wrap .content .right-3').classList.remove('hide-right')
            document.querySelector('.cpty-wrap .content .right-1').classList.add('hide-right')
            document.querySelector('.cpty-wrap .content .right-2').classList.add('hide-right')

            document.querySelector('.cpty-wrap .content .type-btn-1').classList.remove('type-btn-active')
            document.querySelector('.cpty-wrap .content .type-btn-2').classList.remove('type-btn-active')
            document.querySelector('.cpty-wrap .content .type-btn-3').classList.add('type-btn-active')
        }


        document.querySelector('.cpty-wrap .content .right-2').classList.remove('hide-right')
        document.querySelector('.cpty-wrap .content .right-1').classList.add('hide-right')
        document.querySelector('.cpty-wrap .content .right-3').classList.add('hide-right')
        document.querySelector('.cpty-wrap .content .type-btn-1').classList.remove('type-btn-active')
        document.querySelector('.cpty-wrap .content .type-btn-3').classList.remove('type-btn-active')
        document.querySelector('.cpty-wrap .content .type-btn-2').classList.add('type-btn-active')
    }
}
</script>

<style lang="scss" scoped>
@import '../../static/style/product.common.scss';

.cpty-wrap {
    .content {
        padding-top: 50px;
        display: flex;
        align-items: flex-start;

        .left {
            width: 288px;

            .desc {
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 16px;
                color: #02112E;
                letter-spacing: 0;
                text-align: justify;
                margin-bottom: 42px;
            }

            .type-btn {
                width: 288px;
                height: 64px;
                box-sizing: border-box;
                color: #02112E;
                font-size: 20px;
                margin-bottom: 18px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 26px;
                padding-right: 23px;
                box-shadow: 0 0 12px 0 #0000000d;
                cursor: pointer;
                transform: all .3s;

                .label {
                    display: block;

                }

                .icon {
                    width: 15px;
                    height: 22px;
                }

                .icon-w {
                    width: 15px;
                    height: 22px;
                    display: none;
                }

                &:hover {
                    background-color: #0555FF;
                    border-radius: 8px;
                    color: #fff;

                    .icon {
                        display: none;
                    }

                    .icon-w {
                        display: block;
                    }
                }
            }

            .type-btn-active {
                background-color: #0555FF;
                border-radius: 8px;
                color: #fff;

                .icon {
                    display: none;
                }

                .icon-w {
                    display: block;
                }
            }

            .tips {
                width: 288px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 16px;
                color: #828282;
                letter-spacing: 0;
            }

            .link {
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 15px;
                color: #0555FF;
                letter-spacing: 0;
                margin-top: 10px;
                display: flex;
                align-items: center;

                .icon {
                    margin-left: 5px;
                    width: 15px;
                    height: 15px;
                }
            }
        }

        .right {
            transition: all .3s;
            position: relative;
            padding-left: 261px;
            padding-right: 272px;
            margin-left: 65px;

            .card-item {
                width: 245px;
                background: #FFFFFF;
                box-shadow: 0 0 12px 0 #0000000d;
                border-radius: 12px;
                padding: 21px 16px 24px 24px;
                box-sizing: border-box;
                position: absolute;

                .label {
                    font-family: PingFangSC-SNaNpxibold;
                    font-weight: 600;
                    font-size: 20px;
                    color: #02112E;
                    letter-spacing: 0;
                }

                .desc {
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #828282;
                    letter-spacing: 0;
                    text-align: justify;
                    margin-top: 4px;
                }
            }


            .phone {
                width: 250px;
                height: 505px;
            }
        }

        .hide-right {
            display: none;
        }

        .right-1 {
            .card-item-1 {
                top: 0;
                left: 0;
            }

            .card-item-2 {
                top: 169px;
                left: 0;
            }

            .card-item-3 {
                top: 238px;
                right: 0;
            }

            .border-1 {
                width: 199px;
                height: 64px;
                border-bottom: 1px dashed #0555FF;
                border-left: 1px dashed #0555FF;
                position: absolute;
                left: 126.5px;
                top: 306px;

                &::after {
                    content: "";
                    height: 10px;
                    width: 10px;
                    display: block;
                    background-color: #0555FF;
                    border-radius: 50%;
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                }
            }

            .border-2 {
                width: 240.5px;
                height: 45px;
                border-top: 1px dashed #0555FF;
                border-right: 1px dashed #0555FF;
                position: absolute;
                right: 122.5px;
                top: 193px;

                &::after {
                    content: "";
                    height: 10px;
                    width: 10px;
                    display: block;
                    background-color: #0555FF;
                    border-radius: 50%;
                    position: absolute;
                    top: -5px;
                    left: -5px;
                }
            }
        }

        .right-2 {
            .card-item-1 {
                top: 0;
                left: 0;
            }

            .card-item-2 {
                top: 189px;
                left: 0;
            }

            .card-item-3 {
                top: 64px;
                right: 0;
            }

            .border-1 {
                width: 199px;
                height: 65px;
                border-bottom: 1px dashed #0555FF;
                border-left: 1px dashed #0555FF;
                position: absolute;
                left: 126.5px;
                top: 326px;

                &::after {
                    content: "";
                    height: 10px;
                    width: 10px;
                    display: block;
                    background-color: #0555FF;
                    border-radius: 50%;
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                }
            }

            .border-2 {
                width: 240.5px;
                height: 124px;
                border-bottom: 1px dashed #0555FF;
                border-right: 1px dashed #0555FF;
                position: absolute;
                right: 122.5px;
                top: 211px;

                &::after {
                    content: "";
                    height: 10px;
                    width: 10px;
                    display: block;
                    background-color: #0555FF;
                    border-radius: 50%;
                    position: absolute;
                    bottom: -5px;
                    left: -5px;
                }
            }
        }

        .right-3 {
            .card-item-1 {
                top: 0;
                left: 0;
            }

            .card-item-2 {
                top: 189px;
                left: 0;
            }


            .border-1 {
                width: 199px;
                height: 67px;
                border-bottom: 1px dashed #0555FF;
                border-left: 1px dashed #0555FF;
                position: absolute;
                left: 126.5px;
                top: 326px;

                &::after {
                    content: "";
                    height: 10px;
                    width: 10px;
                    display: block;
                    background-color: #0555FF;
                    border-radius: 50%;
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                }
            }
        }
    }
}

.hxgn-wrap {
    .content {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;

        .item {
            width: 558px;
            padding: 36px 26px 30px 34px;
            background-image: linear-gradient(180deg, #FBFCFD 0%, #F6F7FA 100%);
            border-radius: 20px;
            box-sizing: border-box;

            .icon {
                width: 70px;
                height: 70px;
            }

            .label {
                margin-top: 26px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 24px;
                color: #02112E;
                letter-spacing: 0;
                text-align: justify;
            }

            .desc {
                margin-top: 24px;
                width: 498.63px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 16px;
                color: #131424;
                letter-spacing: 0;
                text-align: justify;
            }
        }
    }
}

.yycj-wrap {
    padding: 49px 149px 53px;

    .changjing-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .changjing-item {
            width: 368px;
            border-radius: 12px;
            overflow: hidden;

            .top {
                height: 222px;
                position: relative;

                >img {
                    width: 100%;
                    height: 100%;
                }

                .title {
                    height: 28px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 42px;
                    left: 20px;
                }

                .sub-title {
                    height: 17px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 12px;
                    color: #FFFFFF;
                    position: absolute;
                    bottom: 26px;
                    left: 20px;
                }
            }

            .bottom {
                height: 117px;
                background: #F7F8FB;
                padding: 20px;
                box-sizing: border-box;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 14px;
                color: #828282;
            }
        }
    }
}

.cpys-wrap {

    .youshi-list {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item {
            box-sizing: border-box;
            width: 368px;
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            box-shadow: 0 0 12px 0 #0000000d;
            border-radius: 12px;
            display: flex;
            padding: 30px 20px;

            .left {
                .title {
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 20px;
                    color: #02112E;
                }

                .desc {
                    margin-top: 10px;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 12px;
                    color: #828282;
                    letter-spacing: 0;
                    line-height: 20px;
                }
            }

            .icon {
                width: 64px;
                height: 64px;
                margin-left: 11px;
            }
        }
    }
}

.yhjs-wrap {
    background: #F7F9FC;

    .juese-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        .item {
            width: 368px;
            background: #FFFFFF;
            box-shadow: 0 0 8px 0 #00000005;
            border-radius: 12px;
            padding: 38px 29px;
            box-sizing: border-box;
            transition: all .3s;

            &:hover {

                .title,
                .desc,
                .link {
                    color: #ffffff !important;
                }

                background-color: #003CE2 !important;
            }

            .icon {
                width: 63px;
                height: 62px;
            }

            .icon-w {
                width: 63px;
                height: 62px;
                display: none;
            }

            &:hover {
                .icon {
                    display: none;
                }

                .icon-w {
                    display: block;
                }
            }

            .title {
                margin-top: 34px;
                line-height: 42px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 30px;
                color: #02112E;
                letter-spacing: 0;
            }

            .desc {
                margin-top: 17px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 16px;
                color: #131424;
                letter-spacing: 0;
                line-height: 24px;
            }

            .link {
                margin-top: 20px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 15px;
                color: #003CE2;
                letter-spacing: 0;
                display: flex;
                align-items: center;

                .link-icon {
                    margin-left: 3px;
                    width: 15px;
                    height: 15px;
                }

                .link-icon-w {
                    display: none;
                    margin-left: 3px;
                    width: 15px;
                    height: 15px;
                }
            }

            &:hover {
                .link-icon {
                    display: none;
                }

                .link-icon-w {
                    display: block;
                }
            }
        }
    }
}

.jrlc-wrap {

    .liucheng-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        position: relative;

        .arrow-list {
            position: absolute;
            top: 53px;
            left: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 265px;
            box-sizing: border-box;
            width: 100%;

            .link-arrow {
                display: flex;
                width: 48px;
                height: 48px;
                background: #FFFFFF;
                border-radius: 50%;
                // background-image: url('../../images/product/wlcx/link-right.png');
                background-position: center;
                background-size: 26px 26px;
                background-repeat: no-repeat;
                box-shadow: 0 0 12px 0 #0000000d;
            }
        }

        .item {
            width: 277px;
            background-image: linear-gradient(180deg, #FBFCFD 0%, #F6F7FA 100%);
            border-radius: 12px;

            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 49px 0 38px;

            .icon {
                width: 80px;
                height: 78px;
            }

            .title {
                margin-top: 33px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 20px;
                color: #02112E;
                letter-spacing: 0;
                text-align: center;
            }

            .desc {
                margin-top: 22px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #131424;
                letter-spacing: 0;
                text-align: center;
                line-height: 20px;
                width: 227px;
                height: 40px;
            }
        }
    }

    .contact-btn {
        margin: 50px auto 0;
        width: 201px;
        height: 50px;
        background: #003CE2;
        font-family: PingFangSC-SNaNpxibold;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        border-radius: 4px;
        display: block;
        color: #fff;
        box-shadow: 0 0 12px 0 #003CE20d;
    }
}
</style>