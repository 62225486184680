<template>
    <div class="yingxiao-wrap">
        <div class="banner">
            <h1 class="title">年中大促最高省2万+,<span class="orange-text">注册即可领取</span></h1>
            <div class="sub-title">物流查询|物流订阅|快递拦截|电子面单</div>

            <div class="btn" @click="handleRegisterJump">立刻领取</div>
        </div>

        <div class="gonglue-wrap">
            <div class="title">新手攻略</div>

            <div class="step-wrap">
                <div class="item">
                    <div class="num">01</div>
                    <div class="info">
                        <div class="text-1">注册账号</div>
                        <div class="text-2" @click="handleRegisterJump">点击注册<img class="logo"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/yingxiao/arrow-right.png"
                                alt=""></div>
                    </div>
                </div>
                <div class="item">
                    <div class="num">02</div>
                    <div class="info">
                        <div class="text-1">开通相关的功能服务</div>
                        <div class="text-2" @click="handleRegisterJump">点击开通<img class="logo"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/yingxiao/arrow-right.png"
                                alt=""></div>
                    </div>
                </div>
                <div class="item item-no-arrow">
                    <div class="num">03</div>
                    <div class="info">
                        <div class="text-1">使用物流云的功能</div>
                        <div class="text-2" @click="handleRegisterJump">点击使用<img class="logo"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/yingxiao/arrow-right.png"
                                alt=""></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="chanpin-wrap">
            <div class="title">8款热门产品推荐</div>
            <div class="sub-title">感恩回馈活动</div>

            <div class="chanpin-list">
                <div class="new-user">
                    <div class="title">新用户注册即<span class="orange-text">送大礼包</span></div>
                    <div class="sub-title">年终大促最高可省2万+ 赠送1000次</div>

                    <div class="btn" @click="handleRegisterJump">免费试用</div>

                    <a href="" class="protocol">确定购买即同意《服务电子协议》</a>
                </div>

                <div class="chanpin-item" v-for="(item, index) in rightList" :key="index" @click="gotoLink(item.link)">
                    <div class="label">{{ item.label }}</div>
                    <div class="desc">{{ item.subTile }}</div>
                    <div class="right-list">
                        <div class="item" v-for="(right, rIndex) in item.right" :key="rIndex"><img class="icon"
                                src="https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/yingxiao/check.png"
                                alt="">
                            {{ right }}
                        </div>
                    </div>
                    <div class="num">
                        {{ item.num }}
                        <div class="unit">{{ item.unit }}</div>
                    </div>
                </div>
            </div>
        </div>

        <ContactWrap />

    </div>
</template>

<script>
import ContactWrap from '@/components/Contact-wrap.vue';
export default {
    components: {
        ContactWrap
    },
    data() {
        return {
            rightList: [
                {
                    label: '轨迹订阅',
                    subTile: '支持即时查询，为用户提供全流程的物流状态查询服务。',
                    right: ['物流跟踪，监督卖家发货', '异常提醒，签收状态'],
                    num: 2000,
                    unit: '单',
                    link: '/dtgj'
                },
                {
                    label: '物流查询',
                    subTile: '支持实时查询和订阅推送两种查询方式。',
                    right: ['物流跟踪，监督卖家发货', '异常提醒，签收状态'],
                    num: 2000,
                    unit: '单',
                    link: '/kdcx'
                },
                {
                    label: '时效预测',
                    subTile: '通过出发地、目的地、下单时间，可预测快递寄出后的到达时间。',
                    right: ['物流跟踪，监督卖家发货', '异常提醒，签收状态'],
                    num: 2000,
                    unit: '单',
                    link: '/sxyc'
                },
                {
                    label: '单号识别',
                    subTile: '查询单号时，智能分析，实时更新单号库，保障物流公司编码准确率。',
                    right: ['物流跟踪，监督卖家发货', '异常提醒，签收状态'],
                    num: 100,
                    unit: '次',
                    link: '/dhsb'
                },
                {
                    label: '物流异常预警',
                    subTile: '支持对在途异常运单进行实时监控和消息推送。',
                    right: ['物流跟踪，监督卖家发货', '异常提醒，签收状态'],
                    num: 1000,
                    unit: '单',
                    link: '/wlycyj'
                },
                {
                    label: '订单拦截',
                    subTile: '解决商家或商家客户在发件后需要退回、修改地址的问题。',
                    right: ['物流跟踪，监督卖家发货', '异常提醒，签收状态'],
                    num: 100,
                    unit: '单',
                    link: '/ddlj'
                },
                {
                    label: '商家寄件',
                    subTile: '为寄件用户提供一键下单到快递员，并在2小时上门取件的寄件服务。',
                    right: ['物流跟踪，监督卖家发货', '异常提醒，签收状态'],
                    num: 100,
                    unit: '单',
                    link: '/grjj'
                },
                {
                    label: '国际地址库',
                    subTile: '对海外地址数据的识别-解析-补全等机制识别后解析为详细地址信息。',
                    right: ['物流跟踪，监督卖家发货', '异常提醒，签收状态'],
                    num: 100,
                    unit: '单',
                    link: '/gjdzk'
                }
            ]
        }
    },
    methods: {
        gotoLink(link) {
            this.$router.push(link)
        }
    }
}
</script>

<style lang="scss" scoped>
.yingxiao-wrap {
    background-color: #E8F2FF;
    
    background-image: url('https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/yingxiao/banner-1.png');
    background-size: 100% auto;
    background-repeat: no-repeat;

    .banner {
        padding: 150px 190px 64px;

        .title {
            font-size: 48px;
            font-weight: bold;

            .orange-text {
                color: #F57927;
            }
        }

        .sub-title {
            color: #131424;
            margin-top: 16px;
            font-size: 30px;
            font-weight: 500;
        }

        .btn {
            width: 163px;
            height: 50px;
            background-image: linear-gradient(90deg, #0C8EFF 2%, #0555FF 98%);
            border-radius: 6px;
            margin-top: 24px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            cursor: pointer;
        }
    }

    .gonglue-wrap {
        width: 100%;
        text-align: center;
        padding-bottom: 60px;
        padding: 0 190px;
        box-sizing: border-box;

        .title {
            margin: 0 auto 22px;
            font-size: 36px;
            color: #07205D;
            font-weight: bold;
            line-height: 50px;
        }

        .step-wrap {
            width: 100%;
            border-radius: 12px;
            overflow: hidden;
            display: flex;

            .item {
                box-sizing: border-box;
                width: 405px;
                height: 100px;
                display: flex;
                align-items: center;
                background-image: linear-gradient(270deg, #0C8EFF 0%, #0555FF 100%);
                position: relative;
                padding-left: 45px;

                .num {
                    font-size: 60px;
                    margin-top: 5px;
                    color: #FFFFFF;
                    font-weight: bold;
                    background: linear-gradient(to bottom, #D5E1FC, #91B9FF);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .info {
                    color: #fff;
                    margin-left: 10px;

                    .text-1 {
                        font-size: 22px;
                        font-weight: 500;
                    }

                    .text-2 {
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        .logo {
                            height: 12px;
                            margin-left: 5px;
                        }
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    right: -30px;
                    top: 0;
                    width: 0px;
                    height: 0px;
                    border-left: 30px solid #0c8eff;
                    border-top: 50px solid transparent;
                    border-bottom: 50px solid transparent;
                    border-right: 0px solid transparent;
                    z-index: 999;
                }
            }

            .item-no-arrow {
                &::after {
                    content: "";
                }
            }
        }
    }

    .chanpin-wrap {
        width: 100%;
        // padding: 0 190px;
        margin-top: 60px;
        box-sizing: border-box;
        text-align: center;

        .title {
            margin: 0 auto 8px;
            font-size: 36px;
            color: #07205D;
            font-weight: bold;
            line-height: 50px;
        }

        .sub-title {
            font-size: 18px;
            color: #07205D;
        }

        .chanpin-list {
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            // flex-wrap: wrap;
            margin: 40px auto;
            display: grid;
            grid-template-columns: 364px 364px 364px;
            grid-column-gap: 20px;
            place-items: center;
            width: 1132px;

            .new-user {
                width: 365px;
                height: 307px;
                margin-bottom: 24px;
                border-radius: 14px;
                background-image: url('https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/yingxiao/chanpin-bg-1.png');
                background-size: auto 108%;
                background-position: right;
                box-sizing: border-box;
                padding-top: 48px;
                padding-left: 27px;
                text-align: left;
                border: 1px solid #B4CAF9;

                .title {
                    font-size: 26px;
                    color: #0555FF;

                    margin-bottom: 19px;

                    .orange-text {
                        color: #F57927;
                    }
                }

                .sub-title {
                    color: #0555FF;
                    font-size: 16px;
                    width: 155px;
                }

                .btn {
                    width: 150.2px;
                    height: 50px;
                    background: #FFFFFF;
                    box-shadow: 0 0 10px 0 #0555ff33;
                    border-radius: 8px;
                    margin-top: 47px;
                    text-align: center;
                    line-height: 50px;
                    color: #0555FF;
                    font-weight: bold;
                    cursor: pointer;
                }

                .protocol {
                    color: #0555FF;
                    font-size: 13px;
                    margin-top: 22px;
                    display: block;
                }
            }

            .chanpin-item {
                width: 364px;
                height: 307px;
                margin-bottom: 24px;
                background-image: url('https://llyun.oss-cn-shenzhen.aliyuncs.com/kunzhongyun/website/assets/images/yingxiao/chanpin-bg-2.png');

                border-radius: 14px;
                background-color: #fff;
                box-sizing: border-box;
                padding: 34px 24px;
                text-align: left;
                cursor: pointer;

                &:hover {
                    border: 1px solid #0555FF;
                }

                .label {
                    font-size: 22px;
                    color: #131424;
                    margin-bottom: 10px;
                    font-weight: 700;
                }

                .desc {
                    font-size: 17px;
                    color: #414141;
                    margin-bottom: 30px;
                    min-height: 50px;
                }

                .right-list {
                    .item {
                        font-size: 15px;
                        color: #828282;
                        display: flex;
                        align-items: center;

                        .icon {
                            width: 18px;
                            margin-right: 7px;
                        }
                    }
                }

                .num {
                    font-size: 38px;
                    color: #0555FF;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    margin-top: 26px;

                    .unit {
                        font-size: 26px;
                    }
                }
            }
        }
    }

    // @media screen and (width: 1340px) {
    //     .chanpin-wrap {
    //         padding: 50px;
    //     }
    // }
}</style>