<template>
    <div v-if="visible" class="toast-wrap">
        {{ label }}
    </div>
</template>
<script>
export default {
    data() {
        return {
            visible: false,
            label: 'sdfasdf',
            timer: null
        }
    },
    methods: {
        show(title) {
            this.label = title
            this.visible = true
            setTimeout(() => {
                this.close()
            }, 2000)
        },
        close() {
            this.visible = false
        }
    }
}
</script>
<style lang="scss" scoped>
.toast-wrap {
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);

    background-color: rgba($color: #000000, $alpha: 0.3);
    color: #fff;
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 3px #f5f5f5;
    border-radius: 5px;
}
</style>